@mixin buttonHover($color) {
  background-color: darken($color, 10%);
}

.socialshare-buttons {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 1.5em auto;
  width: 70%;

  .share-button {
    background-position: 5px 6px;
    background-repeat: no-repeat;
    background-size: 8px;
    border-color: transparent;
    border-radius: 2px;
    color: $white;
    padding-left: 20px;

    // https://www.lockedownseo.com/social-media-colors/
    &.facebook {
      background-color:#1877f2;
      background-image: url(../../assets/img/icons/social-networks/facebook/f/white.svg);

      &:hover {
       @include buttonHover(#1877f2);
      }
    }

    &.twitter {
      background-color:#1da1f2;
      background-image: url(../../assets/img/icons/social-networks/twitter/bird/white.svg);
      background-size: 12px;
      padding-left: 22px;

      &:hover {
        @include buttonHover(#1877f2);
      }
    }

    &.linkedin {
      background-color:#007bb5;
      background-image: url(../../assets/img/icons/social-networks/linkedin/in/white.svg);
      background-size: 10px;

      &:hover {
        @include buttonHover(#1877f2);
      }
    }
  }
}

.publishing-header-icon-container {
  width: 200px;
  display: inline-block;
}


.publishing-modal {
  min-height: 600px !important;
}

.publishing-board {
  min-height: 600px !important;
}

/* Search Bar */
.custom-search-input {
  padding: 0;
  position: relative;

  &.embedded-search {
    margin-top: 1px;
  }

  .search-query {
    border-radius: 5px;
    box-shadow: inset 0 0 4px 0 rgba(230, 230, 230, 1);
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 2.375rem;
    width: 100%;

    + .form-control-feedback {
      color: #aaa;
      display: block;
      height: 2.375rem;
      line-height: 2.375rem;
      pointer-events: none;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 2.375rem;
      z-index: 0;
    }

    &:focus {
      z-index: 0;
    }
  }

  button {
    background: none;
    border: 0;
    border-radius: 5px;
    color: #aaa;
    cursor: pointer;
    height: 2.375rem;
    margin-bottom: 0;
    min-width: 2.375rem;
    padding: 2px 5px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

.input-group-text {
  padding-bottom: 0.2em;
  padding-top: 0.2em;
  color:#495057;
}

.input-group-append {
  .input-group-text {
    border-top-left-radius:0;
    border-bottom-left-radius:0;
    margin-top: -1px;
    height: 102%;
  }

  .smaller-text {
    height: 100%;
  }
}

.input-group-prepend {
  .input-group-text {
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    margin-top: -1px;
    height: 102%;
  }

  .smaller-text {
    height: 100%;
  }
}

.input-group {
  input[type=password] {
    top: -1px;
  }
}

.select {
  appearance: none;
  background-color: $white;
  background-image: url(../../assets/img/icons/sort-down/black.svg);
  background-position: right .5em top 25%;
  background-repeat: no-repeat, repeat;
  background-size: .65em auto, 100%;
  color: $primary-colour;
  cursor: pointer;
  padding: 0.2em 2em 0.2em 0.5em;
  width: auto;

  &:disabled {
    background-image: url(../../assets/img/icons/sort-down/light-gray.svg);
    color: #c8c8c8;
  }

  &.select-sm {
    font-size: 12px;
    padding-right: 1.5em;
  }
  @extend %basic-input-style;
}

.standard-checkbox {
  -webkit-appearance: none;
  background-color: #f0f0f1;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  cursor: pointer;
  height: 18px;
  width: 18px;

  & + span {
    bottom: 4px;
    left:5px;
    margin-right:15px;
    position: relative;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: default;
  }

  &:checked {
    background: $primary-colour url(../../assets/img/icons/check/white.svg) no-repeat center/16px 16px;
    border: 1px solid #adb8c0;
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: $white;
  }
}


.standard-checkbox-checked {
  background: $primary-colour url(../../assets/img/icons/check/white.svg) no-repeat center/16px 16px;
  border: 1px solid #adb8c0;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: $white;
}

.standard-checkbox:after, input[type=radio]:after {
  content: "";
  display: none;
  position: absolute;
}

.standard-dropdown {
  -webkit-appearance: none;
  appearance: none;
  background-color: #f5f6fa;
  border-radius: 5.4px;
  box-shadow: inset 0 0 6px 0 rgba($black, 0.2);
  height: 30px;
  margin-bottom: 5px;
  padding-bottom: 2px;
  padding-left: 10px;
  width: 95%;

  &[multiple] {
    height: 80px;
  }
}

.faux-radio {
  border-radius: 12px;
  height: 20px;
  width: 20px;
  @extend %blue-radio;
}

input[type=radio] {
  -webkit-appearance: none;
  background-color: #cecfd0;
  border-radius: 12px;
  cursor: pointer;
  height: 20px;
  margin-right: 5px;
  width: 20px;

  &.small {
    height: 18px;
    margin-right: 0;
    width: 18px;
  }

  & + span {
    color: $header-primary-colour;
    cursor: pointer;
    font-size: 1em;
    margin-right: 2em;
    position: relative;
  }

  &:focus {
    outline: none;
  }
  &:checked {
    @extend %blue-radio;
  }
}

.pill-radio {
  overflow: hidden;
  position: relative;

  input[type=radio] {
    height: 1px;
    position: absolute;
    top: -20px;
    width: 1px;

    & + span {
      border: 1px solid $primary-colour;
      color: $primary-colour;
      display: inline-block;
      font-size: 1em;
      margin-right: 0;
      padding: 2px 8px;
      position: initial;
      user-select: none;
    }

    &:checked + span {
      background-color: $primary-colour;
      color: $white;
    }
  }

  &:first-child {
    span {
      border-bottom-left-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
    }
  }

  &:last-child {
    span {
      border-bottom-right-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }

  & + label.pill-radio {
    margin-left: -1px;
  }
}

select::-ms-expand {
  display: none; /* hide the default arrow in ie10 and ie11 */
}

.custom-switch {
  &.rememberme-switch {
    margin-bottom: 10px;

    .custom-control-label {
      padding-left: 10px;
      vertical-align: middle;

      &::before {
        border: 1px solid #c8ced3;
        border-radius: 50em;
        height: 26px;
        top: -2px;
        width: 40px;
      }

      &::after {
        background-color: $white;
        border: #c8ced3 solid 1px;
        border-radius: 50em;
        height: 20px;
        left: -32px;
        top: 1px;
        width: 20px;
      }
    }

    .custom-control-input:checked {
      & ~ .custom-control-label {
        &::before {
          background-color: $success;
          border-color: darken($success, 10%);
        }

        &::after {
          border-color: darken($success, 10%);
          transform: translateX(14px);
        }
      }
    }
  }
}

.loading-input {
  position: absolute;
  right: 25px;
  top: 5px;
}

.value-display {
  font-size: 0.875rem;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 6px;
}

.edit-inline {
  align-items: stretch;
  display: flex;
  width: 100%;

  div:last-child {
    display: flex;
    margin-left: -1px;
    position: relative;

    .edit-btn {
      background: url(../../assets/img/icons/pencil-alt/primary.svg) center center no-repeat;
      border: none;
      height: 10px;
      margin-left: 0.5em;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-75%);
      width: 10px;
    }

    .save-btn,
    .cancel-btn {
      background-color: #fafbff;
      border: 1px solid $primary-colour;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      color: $primary-colour;
      padding: 0.375rem 0.75rem;

      &:before {
        content: '\2713';
      }
    }

    .save-btn {
      border-radius: 0;
    }

    .cancel-btn {
      background-color: $primary-colour;
      color: $white;

      &:before {
        content: '\2715';
      }
    }
  }

  .form-control,
  .form-control:focus {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.input-range__slider {
  background-color: $primary-colour;
  border-color: $primary-colour;
}

.input-range__track--active {
  background-color: $primary-colour;
}

.react-multi-email {

  [data-tag] {
    font-weight: normal !important;
  }
  @extend %basic-input-style;
}

.manual-address {
  clear: both;
  padding: 1em;
  @extend %basic-input-style;
}

.pm-grid-wrapper {
  max-height: 500px;
  overflow: auto;
  padding: 0 1em 1em;
  position: relative;
  width: 100%;

  .pm-grid {
    width: 100%;

    &.pm-grid-header {
      left: 0;
      position: sticky;
      top: 0;
    }

    > div {
      &.pm-grid-cell,
      &.pm-grid-head-cell {
        border-bottom: 1px solid $dark-grey;
        border-right: 1px solid $dark-grey;
        padding: 0.25em;
      }

      &.pm-grid-head-cell,
      &.pm-grid-top-cell {
        border-top: 1px solid $dark-grey;
      }

      &.pm-grid-start-cell {
        border-left: 1px solid $dark-grey;
      }

      &.pm-grid-head-cell {
        background-color: $white;
      }
    }
  }
  @extend %basic-input-style;
}

.quill {
  .ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .ql-container {
    background-color: $white;
  }

  .ql-tooltip {
    left: unset !important;

    &[data-mode="image"]::before {
      content: 'Image URL';
    }
  }

  &.with-toolbar {
    .ql-container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.quill.disabled {
  .ql-toolbar {
    background-color:#F2F2F2;
  }

  .ql-container {
    background-color:#F2F2F2;
  }
}

.header-search-wrapper {
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-search-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.header-search-input {
  border-radius: 5px;
  box-shadow: inset 0 0 4px 0 rgba(230, 230, 230, 1);
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 2.375rem;
  width: 0;
  transition: width 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
  overflow: hidden;
  opacity: 0;
}

.header-search-wrapper.expanded .header-search-input {
  width: 100%;
  opacity: 1;
}

.header-search-button .fa-search {
  color: rgb(90, 90, 90);
  display: block;
  height: 2.375rem;
  font-size: 20px;
  line-height: 2.375rem;
  pointer-events: none;
  text-align: center;
  font-weight: lighter;
  z-index: 0;
}

.header-search-input:focus {
  z-index: 0;
}

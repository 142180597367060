.detail-title-block {
  color: $darker-grey;
  font-weight: $font-weight-bold;
}

.detail-value-block {
  color: $header-primary-colour;
  font-weight: $font-weight-light;
  padding: 5px 0;
  word-wrap: break-word;
}

.detail-title {
  color: $darker-grey;
  font-weight: $font-weight-normal;
  padding: 5px 0;
}

.detail-value-list {
  color: $header-primary-colour;
}

.details-bar-items {
  .detail-title-block {
    font-weight: $font-weight-light;
  }

  .detail-value-block {
    font-weight: $font-weight-bold;
  }
}

.detail-value {
  color: $header-primary-colour;
  padding: 5px 0;
  word-wrap: break-word;

  &.plain-html {
    font-family: $default-font;
    line-height: 1.5;

    p,
    dl,
    ol,
    ul,
    blockquote,
    table {
      font-family: $default-font;
    }

    ol, ul {
      list-style-position: outside;
      margin: 1em 0 0;
    }

    ul {
      list-style-type: disc;
    }
  }
}

.is-vertical {
  .detail-title {
    margin-bottom: 0.25em;
    margin-top: 1em;
  }

  .detail-title,
  .detail-value {
    padding: 0 0;
  }
}

.detail-rows {
  padding-left: 10px;
  padding-right: 10px;

  > .row:nth-child(even) {
    background-color: #f5f5f5;
  }

  .detail-title {
    padding-left: 7px;
  }
}

.detail-rows-no-nth {
  padding-left: 10px;
  padding-right: 10px;

  .detail-title {
    padding-left: 7px;
  }
}

$slider-padding: 1em;
 $slider-edge-width: 20px;

// plugin reset
.slide-pane__overlay {
  &.overlay-after-open {
    z-index: 1001;
  }
}

.slide-pane {
  max-width: 900px !important;
  min-width: 370px !important;
  width: 100%;

  .slide-pane__header {
    display: none !important;
  }

  .slide-pane__content {
    padding: 0;
     padding-left: calc(#{$slider-edge-width});
  }
}
// end reset

.slide-pane {
  background-color: $slider-background;
   .slider-edge {
     height: 100%;
     left:0;
     padding-top: $slider-padding;
     position: fixed;
     top:0;
     width: $slider-edge-width;

     a {
       display: block;
       text-align: center;
     }
     @include account-linear-gradient($all: true);
   }

  .slider-header {
    align-items: center;
    background-color: $slider-header-background;
    display: flex;
    padding: $slider-padding;

    h2 {
      font-size: 1.25em;
      margin-bottom: 0;
    }

    .close-btn {
      margin-left: auto;
    }
  }

  .slider-body {
    padding: $slider-padding;
  }
}

.video-interview-slider-overlay {
  background: none !important;
}

.video-interview-slider-overlay.slide-pane__overlay {
  left: auto;
  width:500px;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 750px;

  &.auth-page {
    min-width: 100vw;
  }
}

.app-header {
  background: $white;
  border: none;
  position: fixed;
  z-index: 1001;

  &.container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }

  .app-header-inner {
    align-items: center;
    display: flex;
    height: 60px;
  }

  .mobile-nav {
    display: none;
    position: relative;

    @media (max-width: 992px) {
      display: block;
      margin-left:-1.1em;
    }
  }

  .vertical-nav {
    background-color: $white;
    border: 1px solid #c8ced3;
    border-radius: calc(0.25rem - 1px);
    position: absolute;
    text-align: left;
    top: 49px;
    z-index: 1;

    &.collapsed {
      display: none;
    }
  }

  .main-nav {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    height: 100%;
    margin-left: 1em;
    padding-bottom: 0.75em;
    position: relative;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .head-search {
    align-items: center;
    display: flex;

    .custom-search-input {
      display: none;

      @media (max-width: 992px) {
        display: block;
        width: 200px;
      }

      @media (min-width: 1200px) {
        display: block;
      }
    }
  }

  .nav-link {
    color: $primary-colour;
    white-space: nowrap;
    padding: 0.5rem 1rem;

    &.logo-link {
      padding: 0;
    }

    &.ci-link {
      padding-right: 0;
      text-decoration: none;
      padding-left:0.2rem;
    }
  }

  .nav-item {
    align-items: flex-end;
    background-color: $white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: $primary-colour;
    display: flex;
    font-size: 1em;
    font-weight: $font-weight-bold;
    margin-left: 8px !important;

    &:last-child {
      margin-right: 10px !important;
    }

    &:hover,
    &.selected {
      background-color: $primary-colour;
      color: $white;
    }

    // span {
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    //   width: 100%;
    // }

    // @media (min-width: 992px) {
    //   // margin-left: 10px !important;
    //   padding-left: 0.75rem;
    //   // padding-right: 0.75rem;
    //   max-width: 90px;
    // }

    // @media (min-width: 1200px) {
    //   // margin-left: 15px !important;
    //   // padding-left: 1rem;
    //   // padding-right: 1rem;
    //   max-width: 140px;
    // }
  }

  @media (max-width: 992px) {
    .account-name-dropdown {
      margin-right: 1em;
    }
  }

  @media screen and (min-width: 768px) and (max-device-width : 800px) {
    .account-name-dropdown {
      margin-right: auto;
    }
  }
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-top: 60px !important;
  overflow-x: hidden;

  .main {
    flex: 1 1;
    min-width: 0;
    overflow-x: auto;

    .container-fluid {
      // .details-bar {
      //   background-color: $white;
      // }
      padding: 0 20px 0;

      .details-bar {
        background-color: $white;
        margin-left: -20px;
        margin-right: -20px;
        padding: 20px;
      }
    }
  }
}

.app-footer {
  align-items: center;
  background-color: $white;
  color: $primary-colour;
  display: flex;
  flex: 0 0 50px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
}

.sidebar-container {
  border-bottom: 1px solid $dark-grey;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.no-border {
    border-bottom: none;
    padding-bottom: 0;
  };

  .sidebar-head {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 1.25rem;
    user-select: none;

    &:focus {
      outline: none;
    }

    .side-bar-title {
      color: $header-primary-colour;
      flex: auto;
      font-size: 1em;
      font-weight: $font-weight-bold;
      margin-bottom: 0;
    }
  }
}

.bg-white {
  background-color: $white;
}

.ml-m-5 {
  margin-left: -5px;
  box-shadow: 1px 0 10px rgba(82, 82, 82, 0.1);
}


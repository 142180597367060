.dashboard-toolbar {
  align-items: center;
  display: flex;
  margin-left: auto;

  .btn {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dashboard-grid-wrapper {
  padding-top: 1.5em;
}

.react-grid-layout {
  // background-color: #eee;
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  &.card {
    border-color: rgba($black, 0.125);

    .card-body {
      overflow: hidden;
    }

    .card-body,
    .card-header {
      padding: 0.25em;
    }

    .card-header {
      background-color: #dcdfea;

      span {
        margin-left: 10px;
        font-weight: bold;
      }
    }
  }

  &.cssTransforms {
    transition-property: transform;
  }

  &.resizing {
    opacity: 0.9;
    will-change: width, height;
    z-index: 1;
  }

  &.react-draggable-dragging {
    transition: none;
    will-change: transform;
    z-index: 3;
  }

  &.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    user-select: none;
    z-index: 2;
  }

  .number-stat-dashboard {
    font-size: 45px;
    float: left;
    font-weight: $font-weight-bold;
    color: $secondary-colour;
    height: 55px;
  }

  .number-stat-dashboard > a {
    color: $secondary-colour;
    text-decoration: none;
  }

  .number-stat-dashboard > a:hover {
    text-decoration: none;
  }

  .react-resizable-handle {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=);
    background-origin: content-box;
    background-position: bottom right;
    background-repeat: no-repeat;
    bottom: 0;
    box-sizing: border-box;
    cursor: se-resize;
    height: 20px;
    padding: 0 3px 3px 0;
    position: absolute;
    right: 0;
    width: 20px;
    z-index: 10;
  }

  &.react-resizable-hide {
    .react-resizable-handle {
      display: none;
    }
  }

  &.static {
    .react-resizable-handle {
      display: none;
    }
  }
}

.dashboard-slider {
  .add-panel-item {
    cursor: pointer;
    font-size: 1.25em;
    margin-bottom: 0.5em;

    a {
      display: block;

      &:hover {
        background-color: #f0f1f5;
      }
    }

    span {
      color: #bbb;
    }
  }
}

.panel-content {
  height: 100%;

  &.error-panel,
  &.is-loading-panel {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .fc-list-heading {
    display: none;
  }

  .fc-toolbar {
    &.fc-header-toolbar {
      margin-bottom: 0.5em;
    }
  }
}

.calendar-list-panel {
  .fc-list-event {
    cursor: pointer;
  }
}

.overflow-y {
  overflow-x: hidden;
  overflow-y: auto;
}

@mixin account-linear-gradient($dir: 0deg, $all: false) {
  @if $all {
    background-image: linear-gradient(
      $dir,
      var(--SECONDARY-COLOUR, $secondary-colour),
      var(--GRADIENT-COLOUR-2),
      var(--PRIMARY-COLOUR, $primary-colour),
      var(--GRADIENT-COLOUR-4)
    );
  } @else {
    background-image: linear-gradient(
      $dir,
      var(--SECONDARY-COLOUR, $secondary-colour),
      var(--PRIMARY-COLOUR, $primary-colour)
    );
  }
}

@mixin touch-full-page-panel($top: true, $zIndex: 1, $color: #f0f1f5) {
  background-color: $color;
  bottom: 0;
  display: block;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  z-index: $zIndex;
  @if $top {
    top: 0;
  }
}

.language-toggle {
  .language-btn {
    border-color: $primary-colour;

    &.selected {
      background-color: lighten($color: $primary-colour, $amount: 15);
    }

    &:hover {
      background-color: lighten($color: $primary-colour, $amount: 20);
    }
  }
}

/* styles.css - make sure to import this in your component */


.default-node {
  border: none; /* Solid border for definition */
  background-color: #ffffff; /* White background */
  padding: 10px; /* Spacing inside the node */
  border-radius: 8px; /* Rounded corners for a modern feel */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
  font-size: 0.875rem; /* Consistent and readable font size */
  color: #1A2E56; /* Dark text for readability */
  display: flex; /* Use flex layout */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text */
  overflow: hidden; /* Prevent content spillover */
  width: 200px; /* Set a fixed width to maintain node alignment */
  transition: background-color 0.3s ease-out;
}

.react-flow__node {
  border: none ;

}
/* Style the edges with labels differently if preferred */
.react-flow__edge-label {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 2px 4px;
}

/* Based on node positions, we may want to adjust edge styles for better visibility */
.react-flow__edge-path {
  stroke: #222;
  stroke-width: 2px;
}

/* Add a hover effect */
.default-node:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Style for labels inside nodes */
.default-node .react-flow__node-data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

/* You can adapt this to create specific styles for input/output/default types */
.default-node.is-input {
  border-color: #36b37e;
}

.default-node.is-output {
  border-color: #ff5630;
}

/* Style for selected nodes */
.react-flow__node.default-node.selected {
  border-color: #0052cc;
}

.react-flow__attribution {
  display: none !important;
}

%basic-input-style {
  border: solid 1px $dark-grey;
  border-radius: 5px;
  margin-top: -0.05em;
  box-shadow: inset 0 0 3px 0 rgba($black, 0.1) !important;
}

%basic-input-style-focused {
  border: solid 1px $dark-grey !important;
  @extend %basic-input-style;
}

%blue-radio {
  background: $primary-colour url(../../assets/img/icons/check/white.svg) no-repeat center/18px 18px !important;
  border: 1px solid #010202;
  box-shadow: 0 1px 2px rgba($black, 0.05), inset 0 -15px 10px -12px rgba($black, 0.05), inset 15px 10px -12px rgba($white, 0.1);
  color: $white;
  margin-top: -0.05em;
}

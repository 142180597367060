.ReactTable {
  border: none !important;
  font-size: 1em;

  .rt-table {
    // background-color: $white;
    // border-bottom: 1px solid $dark-grey;
    overflow: initial;

    .standard-checkbox {
      position: relative;
      top: 2px;
    }
  }

  .rt-thead {
    position: relative;

    &.-header {
      background-color: transparent;
      box-shadow: none !important;
      color: $primary-colour;
    }

    .rt-tr {
      align-items: center;
      font-weight: $font-weight-bold;
      text-align: left !important;
    }

    .rt-th {
      border-right: none !important;
      font-size: 0.85em;
      text-transform: uppercase;
      padding: 0 !important;

      &.-sort-asc,
      &.-sort-desc {
        box-shadow: none;

        div:first-child::after {
          content: '\25BE';
          display: block;
          position: absolute;
          right: 14px;
          top: 8px;
        }
      }

      div:first-child {
        position: relative;
        padding: 10px 15px !important;
      }

      &.-sort-asc div:first-child::after {
        top: 10px;
        right: 14px;
        transform: rotate(180deg);
      }
    }
  }

  .rt-tbody {
    background-color: $white;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: initial;

    .rt-tr-group {
      border-bottom-color: $light-grey;

      &:first-child {
        .rt-tr {
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }
      }

      &:last-child {
        .rt-tr {
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }

    .rt-tr:not(.-padRow):hover {
      background-color: $hover-grey !important;
    }
  }

  .rt-th,
  .rt-td {
    font-weight: $font-weight-bold;
    padding: 10px 15px !important;
  }

  .rt-td {
    align-self: center;
    border-right-color: transparent !important;

    &.select-cell,
    &.favourite-cell {
      text-align: center;
    }

    &.action-cell {
      overflow: visible;
      position: relative;
      text-align: center;
    }
  }

  .-loading {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .truncate-overflow {
    max-height: 60px;
    overflow: hidden;
    position: relative;
    white-space: normal;
  }

  &.has-pagination {
    .rt-tbody {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .rt-tr-group {
        &:last-child {
          .rt-tr {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}

.ReactTable.no-cell-borders .rt-tbody .rt-tr-group, .rt-td  {
  border: none !important;
}

.h-scroll-table {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 300px;
  height: fit-content;
  padding-bottom: 2rem;
  
  .table-wrapper {
    flex: auto;
  }
}

.pagination-bottom {
  background-color: $white;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: 1px solid $dark-grey;
}

.table__page-management {
  display: flex;
  justify-content: center;
  padding: 10px;

  .table__pagination {
    display: flex;
    flex: 1 1 0;
    justify-content: center;
  }

  .table__pageButton {
    background-color: transparent;
    border: none;
    color: $primary-colour;
    cursor: pointer;
    font-size: 1em;
    outline: none;
    padding-left: 6px;
    padding-right: 6px;

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      color: $dark-grey;
      cursor: not-allowed;

      &:hover {
        text-decoration: none;
      }
    }

    &.table__pageButton--active {
      color: $action-colour;
      font-weight: $font-weight-bold;
    }
  }

  .table__page-size--select {
    background-image: url(../../assets/img/icons/chevron/chevron-down-action.svg);
    background-position: 90% 50%;
    color: $primary-colour;
    font-size: 1em;
  }

  .table__column-manager {
    .table__pageButton {
      position: relative;

      &.is-managed::after {
        color: $primary-colour;
        content: '\02022';
        display: block;
        position: absolute;
        right: -1px;
        top: -3px;
      }
    }
  }
}

.table-manager {
  align-items: center;
  display: flex;

  h6 {
    color: $primary-colour;
  }

  .table-manager-available-cols,
  .table-manager-display-cols {
    width: 40%;

    .column-list {
      border: 1px solid $light-grey;
      border-radius: 4px;

      .column-list-body {
        padding: 0.5em 0.5em 0;

        li {
          display: flex;
          padding: 0.5em 0;
          position: relative;

          .form-check-label {
            span {
              text-transform: capitalize;

              &.is-disabled {
                opacity: 0.5;
              }
            }
          }

          &[data-header="CV"] {
            .form-check-label {
              span {
                text-transform: uppercase;
              }
            }
          }

          .order-control {
            align-items: center;
            display: flex;
            margin-left: auto;
            margin-right: 0.75em;

            button {
              padding: 0;
            }
          }

          // &:hover {
          //   .order-control {
          //     display: flex;
          //   }
          // }
        }
      }

      .column-list-header {
        border-bottom: 1px solid $light-grey;
        padding: 0.5em;
      }

      .column-list-wrapper {
        height: 200px;
        overflow: auto;
        padding-top: 0.5em;
      }

      .form-check-label {
        align-items: center;
        cursor: pointer;
        display: flex;

        .form-check-input {
          margin-top: 0;
        }

        span {
          bottom: 0;
          left: 0;
          margin-left: 0.5em;
        }
      }
    }
  }

  .table-manager-button-cols {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    width: 20%;

    button:first-child {
      margin-bottom: 1em;
    }
  }
}

.activity-table-container {
  min-height: 380px;
  overflow: auto;
  padding: 10px;
}

.activity-table-customise-container {
  display: inline-block;
  width: 230px;
}

.activity-table-customise-container-label {
  display: inline-block;
  margin-right: 5px;
  width: 115px;
}

.activity-table-customise-colour-block {
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.flexiform-wrapper .card-body {
  padding: 1rem;
}

.flexiform {
  legend {
    border-bottom: 1px solid #5c6873;
    padding-bottom: 0.5em;
  }

  .flexiform-section {
    border: 1px solid $light-grey;
    padding: 0.5rem;

    &.is-restricted {
      border-color: lighten($danger, 20%);
    }
  }

  .invalid-feedback {
    font-size: 95%;
  }
}

.flexiform-question {
  border-bottom: 1px solid $light-grey;
  margin-bottom: 1em;
  padding-bottom: 1em;

  .standard-checkbox {
    &[type=checkbox] {
      margin-right: 0;
      margin-left: 0;
      //margin-top: -0.1em;
    }

    &[type=radio].dummy {
      background-color: #f0f0f1;
    }

    &:disabled {
      &:checked {
        background-color: rgba(124, 137, 227, 0.5);
      }

      + span {
        color: rgba(153, 153, 153, 0.8);
      }
    }
  }

  .rules-group {
    align-items: center;
    display: flex;
    position: relative;
    top: -4px;

    .form-control {
      margin-left: 0.25em;
      width: 3.5em;

      &:disabled {
        color: rgba(153, 153, 153, 0.8);
      }
    }
  }

  .width-dropdown {
    margin-left: 0.5em;
    position: relative;
    top: 4px;

    label {
      margin-right: 0.25em;

      &.is-disabled {
        color: rgba(153, 153, 153, 0.8);
      }
    }
  }
}

.flexiform-list {
  li {
    align-items: center;
    display: flex;

    .form-link {
      margin-right: 0.5em;
    }

    .badge {
      font-weight: $font-weight-normal;
      margin-left: auto;
      margin-right: 0.5em;
      padding: 0.3em 0.5em 0.4em;
      text-transform: capitalize;
    }
  }
}

.question-option-group {
  align-items: center;
  display: flex;

  .form-check-input {
    margin: 0;
    position: initial;
  }

  .form-control {
    flex: 1;
    margin-left: 1em;

    &:disabled {
      color: rgba(153, 153, 153, 0.8);
    }
  }

  .close {
    margin-left: 0.5em;
  }
}

.option-feedback {
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

.flexiform-preview-frame {
  background-color: $white;
  border: none;
}

.logic-wrapper {
  .logic-input-wrapper {
    .select {
      width: 100%;
    }

    .form-check {
      margin-bottom: 0.5em;

      .form-check-input {
        margin-top: 0.1em;
      }

      span {
        bottom: 1px;
      }
    }
  }
}

.flexiform-toolbar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.restricted-marker {
  color: $danger;
  margin-right: 0.25em;
}
.flexiform input[type=checkbox] + span {
  top: 1px
}
.access-code-wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 60px;

  .access-code-inner {
    transform: translateY(-60px);
  }

  .access-code {
    display: flex;
    justify-content: center;
    margin: 1em 0 1.5em;

    input {
      margin-right: 0.5em;
    }

    input:last-child {
      margin-right: 0;
    }
  }

  .access-controls {
    display: flex;
    justify-content: center;
  }
}
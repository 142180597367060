.employment-position {
  .position-description {
    // height: 105px;
    // overflow: hidden;
    // transition: height 0.3s ease-out;

    // &.description-content-open {
    //   height: auto;
    // }
  }
}

.competencies {
  .collapsible-list-item {
    button.plaintext {
      position: relative;

      &::after {
        content: '\25B8';
        position: absolute;
        right: -15px;
      }

      &.is-open::after {
        top: 2px;
        transform: rotate(90deg);
      }
    }
  }
}

.ql-align-center {
  text-align: center;
}

.rtw-outcome-icon-success {
  font-size: 14px;
  color: #3ea662 !important;
  position: relative;
  top: -1px;
}

.rtw-outcome-icon-failed {
  font-size: 12px;
  color: #d82b34 !important;
  position: relative;
  top: -1px;
}

.documents-requested-container {
  border:1px solid #C6CBCF;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.document-requested-container {
  border-top: 1px solid #C6CBCF;
  margin: 10px 0 10px 0;
  padding: 10px 0 10px 0;
}

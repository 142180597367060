.react-tags__search-input {
  background-color: transparent;
  border: none;
  cursor: pointer;
  // height: calc(1.5em + 0.75rem + 4px);
  padding: 2px 2px 2px 4px;
  width: 100px !important;

  &:focus {
    background-color: $white;
    border: solid 1px #c6cbd0 !important;
    border-radius: 5.4px !important;
    box-shadow: inset 0 0 3px 0 rgba($black, 0.1) !important;
    cursor: text;
    outline: none;
    width: 100% !important;
  }
}

.react-tags__selected-tag {
  align-items: center;
  background-color: $blue;
  border-radius: 6px;
  color: $white;
  display: inline-flex;
  font-weight: $font-weight-light;
  margin-bottom: 0.5em;
  margin-right: 0.3em;
  max-width: 155px;
  padding: 0.2em 0.5em;
  white-space: nowrap;

  .react-tags__selected-tag-name {
    font-size: 85%;
    font-weight: $font-weight-bold;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
  }

  .react-tags__selected-tag-delete {
    margin-left: 0.5em;

    a {
      color: $white;
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.react-tags__suggestions {
  position: absolute;
  width: 100%;

  ul {
    background: #fafbff;
    border-radius: 6px;
    box-shadow: 0 5px 10px 0 rgba($black, 0.15);
    max-height: 400px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    width: 90% !important;
    z-index: 100;

    li {
      color: $primary-colour;
      cursor: pointer;
      font-size: 13px;
      list-style: none;
      padding: 5px;

      mark {
        background: none;
        font-weight: $font-weight-bold;
        padding: 0;
        text-decoration: underline;
      }

      &:hover {
        background: #f0f1f5;
        color: $secondary-colour;
      }
    }
  }
}

.table-cell-tag-wrapper {
  .react-tags__selected-tag {
    &.inline-tag {
      margin-bottom: 0;
    }

    &.multi-tag {
      max-width: 80%;
    }
  }
}

.tag-checkbox-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;

  input[type=checkbox] {
    margin-right: 0.5em;
  }

  .edit-wrapper {
    flex-grow: 1;

    &:focus {
      outline: none;
    }
  }
}

.group-tags {
  .scroll-wrapper {
    max-height: 250px;
    overflow-y: auto;
  }

  .tag-list {
    align-items: center;
    display: grid;
    gap: 0.5em;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }

  .tag-list-cell {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .button-wrapper {
    align-items: center;
    border-top: 1px solid #d8d8d8;
    display: flex;
    justify-content: space-evenly;
    padding: 0.5em 0;

    button {
      border-radius: 0.2rem;
    }
  }

  &.no-tags {
    .tag-list {
      padding: 0;
    }

    .button-wrapper {
      border-top: none;
    }
  }
}

.tag-grid {
  align-items: center;
  column-gap: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 0.5em;
}

.Toastify__toast-container {
  padding: 0;
  width: 35%;

  &.Toastify__toast-container--top-center {
    left: 0;
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast {
    border-radius: 4px;
  }
}

.Toastify__close-button--light {
  color: $white;
  opacity: 0.7;
}

.Toastify__toast-body {
  div:not(.Toastify__toast-icon) {
    text-align: center;
    width: 100%;
  }
}

// .Toastify__toast-container--top-center {
//   top: 0;
// }

// .Toastify__toast {
//   border-radius: 0;
// }

.Toastify__toast--success {
  background: $success;
  color: $white;
  text-shadow: 1px 1px #b7b7b7;
}

.Toastify__close-button--success {
  color: $white;
}

.Toastify__toast--error {
  background: $danger;
  color: $white;
  text-shadow: 1px 1px #b7b7b7;
}

.Toastify__toast--warn {
  background: $warning;
  color: $black;
}

.toast-fill-top {
  width:100%;
}

.modal-header {
  border-radius: 10px 10px 0 0;
  background-color: $white;
}

.modal-title {
  color: $header-primary-colour;
  margin-left: 5px;
}

.modal-content {
  border: none;
  border-radius: 10px;
  height: calc(100% - 3.5rem);
  overflow: visible;
}

.modal-body {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  padding: 20px 20px 5px 20px;
}

.modal-footer {
  border-top: none;
}

.modal-body > iframe {
  padding:3px;
}

.modal {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(87, 100, 110, 0.33);
  outline-offset: 2px;
}

.tippy-box[data-theme~='ats'] {
  background-color: $info;
  color: $white;
  text-align: center;

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: $info;
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: $info;
  }

  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: $info;
  }

  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: $info;
  }
}

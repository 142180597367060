.timeline-wrapper {
  margin-top: 1em;

  .timeline-entry {
    align-items: flex-start;
    display: flex;
    margin-bottom: 1em;
    
    .tl-icon {
      align-items: center;
      background-color: $hover-grey;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 40px;
      justify-content: center;
      width: 40px;

      [data-icon='Email'],
      [data-icon='File'] {
        width: 1.5em;
      }
    }

    .tl-container {
      border-bottom: 1px solid $hover-grey;
      display: flex;
      flex-grow: 1;
      padding-bottom: 1em;
      padding-left: 1em;

      time {
        font-weight: $font-weight-light;
      }

      .tl-entry-data {
        flex-shrink: 0;
        max-width: 100%;
      }

      .tl-title {
        color: $secondary-colour;
        font-weight: $font-weight-bold;
      }

      .tl-toggle {
        padding: 0.15em 1.5em;
      }

      .tl-content {
        flex-grow: 1;
        margin-left: 1em;

        div {
          border: 1px solid transparent;
          border-radius: 5px;
          height: 0;
          margin-top: 1em;
          max-height: 300px;
          overflow: auto;
          padding: 0;
          word-break: break-word;

          &.is-open {
            border-color: $dark-grey;
            height: auto;
            padding: 0.5em;
          }
        }
      }
    }
  }
}

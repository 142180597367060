@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loading-container {
  background-color: $body-background;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top:0;
  z-index: 10;

  img,
  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.loading-page {
    top: 50px;

    img {
      transform: translate(-15px, -50%);
    }
  }

  &.loading-modal {
    background-color: $white;
    position: absolute;
  }

  &.loading-embedded {
    background-color: transparent;
    bottom: initial;
    height: 65px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.loading-not-fixed {
    position: relative;
    transform: translateY(0);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

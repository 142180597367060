.filter-selector {
  background-color: $white;
  border-radius: 6px;
  margin-top: 5px;

  .filter-selector-header {
    border-bottom: 1px solid $light-grey;
    display: block;
    padding: 8px 10px;

    > p {
      color: $header-primary-colour;
      font-size: 16px;
      font-weight: $font-weight-bold;
    }
  }

  ul li > div {
    align-items: center;
    color: $primary-colour;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    padding: 8px 10px;

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:hover {
      background-color: $hover-grey;
    }

    svg {
      margin-right: 5px;
      width: 1.25em;

      &.map-marker,
      &.industry,
      &.user,
      &.file-form-alt {
        margin-left: 2px;
        margin-right: 6px;
        width: 0.9em;
      }

      &.file-form-alt {
        margin-right: 10px;
      }
    }
  }
}


.filter-header {
  line-height: 35px;
  min-height: 35px;
}

.filter-title {
  color: $header-primary-colour;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: $font-weight-bold;
  height: 26px;
  letter-spacing: normal;
  line-height: 1.44;
}

.filter-body {
  background-color: $white;
  border-radius: 6px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 8px;
  position: relative;

  .close {
    position: absolute;
    right: 7px;
    top: 7px;
  }
}

.filter-body-type {
  font-weight: $font-weight-light;
}

.filter-body-label {
  color: #48525a;
  font-size: 1em;
  font-weight: $font-weight-bold;
  margin-right: auto;
}

.filter-body-label > a {
  color: $header-primary-colour;
  display: block;
  font-size: 14px;
  max-width: 189px;
  max-width: 12vw;
}

.filter-checkboxes-wrapper {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-close-icon {
  transform: translateY(-2px);
}

.filter-information-body {
  background-color: $white;
  border-radius: 6px;
  padding: 8px;
  z-index: 1;

  .filter-information-option, 
  .filter-input-wrapper {
    &.form-check {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    input[type=radio] {
      top: 0;
      position: relative;
      margin-top: 0;
    }
    span {
      top: 0;
      left:1px;
    }
    .form-select {
      padding: 6px;
      font-size: inherit;
      background-image:none;
    }
  }

  .filter-input-wrapper {
    > .SingleDatePicker > .SingleDatePickerInput {
      border: solid 1px $primary-colour;
    }

    .DateInput {
      width: 80%;
    }
  }
}

.filter-checkbox-inline {
  display: flex;

  input + span {
    bottom: 2px;
    margin-right: 0;
    max-width: 12vw;
  }
}

.funnel-filter {
  .funnel-filter-stage {
    background-color: $white;
    // border: 1px solid $light-grey;
    border-radius: 6px;
    margin: 0.5em 0;
    overflow: hidden;

    .stage-inner {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
      margin-bottom: 0;
      padding: 0.5em 0.5em;
      position: relative;

      input {
        display: none;
        margin: 0;
        position: initial;
      }

      & > span {
        bottom: 0;
        left: 0;
        margin-right: 0;
        max-width: 8vw;
        user-select: none;
      }

      .funnel-filter-app-count {
        align-items: center;
        background-color: $misty-grey;
        color: $white;
        display: flex;
        font-weight: $font-weight-bold;
        height: 100%;
        justify-content: center;
        padding: 0 0.5em;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;

        span {
          user-select: none;
        }
      }
    }

    &.is-selected {
      background-color: $secondary-colour;
      color: $white;

      .funnel-filter-app-count {
        background-color: darken($secondary-colour, 8%);
      }
    }
  }
}

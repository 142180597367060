.swimlane-container {
  display: inline-flex;
  word-break: break-word;
  overflow: visible;
  flex-grow: 1;
  width: 100%;
  margin-bottom: -48px;
  height: calc(78vh - 60px);
  padding-bottom: 20px;
  min-height: 300px;

  @media (max-height: 888px) {
    height: calc(65vh);
  }

  @media (min-height: 1240px) {
    height: calc(80vh);
  }
}

.swimlane-column {
  border: 1px solid rgb(223, 227, 235);
  border-image: initial;
  flex-shrink: 0;
  width: 290px;
  height: calc(96%);
  overflow-y: hidden;
  background-color: rgb(245, 248, 250);
  border-radius: 4px;
  margin-right: 7px;
  .badge {
    padding: 0.2rem 0.4rem;
    background-color: rgb(180, 180, 187);
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
  .badge-blue {
    background-color: $action-colour;
  }
}

.wf-badge {
  padding: 0.2rem 0.4rem;
  background-color: $action-colour;
  color: white;
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;
  border-radius: 0.25rem;
}

 .swimlane-entry-container {
   display: flex;
   -webkit-box-flex: 1;
   flex-grow: 1;
   flex-shrink: 0;
   overflow-y: auto;
   overflow-x: hidden;
   height: calc(86%);
   position: relative;
   flex-direction: column;
   @media (min-height: 888px) {
     height: calc(91%);
   }
 }

.swimlane-entry-container.dragging-over {
  -webkit-transition: 300ms ease-in-out;
  -moz-transition: 300ms ease-in-out;
  -o-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
  background-color: rgb(220, 223, 224);
}


.swimlane-card {
  margin: 2px 4px 0 4px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  -webkit-transition: 100ms ease-in-out;
  -moz-transition: 100ms ease-in-out;
  -o-transition: 100ms ease-in-out;
  transition: 100ms ease-in-out;
  box-shadow: rgba(179, 186, 192, 0.2) 0px 1px 1px, rgba(191, 195, 203, 0.2) 0px 0px 1px
}

.swimlane-card-error {
 animation: error-pulse 1s;
}

.swimlane-card-success {
 animation: success-pulse 1s;
}

@keyframes error-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(245, 125, 125, 0.7);
  }

  70% {
    box-shadow: 0 0 0 3px rgba(234, 59, 59, 0.7);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(239, 144, 144, 0.7);
  }
}

@keyframes success-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(157, 243, 160, 0.7);
  }

  70% {
    box-shadow: 0 0 0 3px rgba(91, 212, 97, 0.7);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(157, 243, 160, 0.7);
  }
}

.swimlane-card.is-dragging {
  box-shadow:  0 0 0 3px rgba(80, 148, 222, 0.49)
}

.swimlane-card-overdue {
  background-color: rgba(255, 199, 139, 0.65);
}

.swimlane-card:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 10px 0;
}


.swimlane-header {
  padding: 12px 8px;
  font-weight: bold;
  color: #5A5A5A;
  border-bottom: 1px solid rgb(223, 227, 235);
  text-transform: uppercase;
  font-size: 0.85em;
  margin-bottom: 3px;
}

.swimlane-card-header {
  font-weight: 500;
  color: $header-primary-colour;
  padding: 0;
  padding-left: 0px;
  cursor: pointer;
  font-size: 15px;
}

.swimlane-card-body {
  padding: 5px;
  margin-bottom: 5px;

  .row {
    padding-bottom: 1.5px;

    &:last-child {
      padding-bottom: 0px;
    }

    .react-tags__selected-tag {
      font-size: 77%;
    }
  }
}

.swimlane-selected-card {
  background-color: #adc8ff;
}

.swimlane-detail-key {
  font-weight: bold;
  font: $default-font;
  margin:0 5px 0 0;
}
.swimlane-detail-value {
  display: inline-block;
}

.indent {
  margin-left: 18px;
}

.swimlane-text-warning {
  color: #EC9232;
}

.swimlane-text-danger {
  color: #f5362b;
}

.swimlane-wrap-inner {
  height: calc(100% - 50px);
  overflow: hidden;
}

.swimlane-wrap-outer {
  width: 40%;
  float: left;
  height: 100%;
}

.swimlane-cv-btn {
  border: none;
  font-size: 15px;
  margin-left: 3px;
  padding-right: 6px;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: auto;
}

.swimlane-cv-btn:hover,.swimlane-cv-btn:focus {
  background-color: transparent;
  color: black;
  border: none;
}

body {
  background-color: $body-background;
  color: $primary-colour;
  font-family: $work-sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  &.datalist-open,
  &.modal-open,
  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

main {
  display: block;
}

.inline {
  display: inline-block !important;
}



h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $header-primary-colour;
  font-family: 'Open Sans', sans-serif;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  // font-family: $ws-fam;
  margin: 0;
}

button {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

a {
  color: $action-colour;
  text-rendering: optimizeLegibility;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.form-check-inline .form-check-label{
  margin-bottom: 0.2em;
}
.flex-grow {
  flex: 1;
}

.clearboth {
  clear: both;
}

.login-background-image {
  background-image: url(../assets/img/brand/background/tf-login-background.webp);
  background-size: cover;
}

/* bug to be fixed on reactstrap all rowgroups have excess margin bottom
 https://github.com/reactstrap/reactstrap/pull/2676*/
.row.mb-3{
  margin-bottom: 0.5rem !important;
}

.disabled-margin-bottom.mb-3{
  margin-bottom: inherit !important;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
  border-top-width: 1px;
}

.rc-collapse .rc-collapse-content{
overflow: visible;
padding: 1px 16px;
}
.form-editor-background {
  background-color: #212121;
}
.loading-screen{
  width: 48%;
}


// AP-2550 - cutting off cv on small screens.
.react-pdf__Document {
  width: 100%;
}

.react-pdf__Page__canvas {
  margin: auto;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: unset;
}
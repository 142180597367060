.skill-pill {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  margin: 3px;
  border-radius: 20px;
  background-color: #f0f5ff;
  border: 1px solid #e1e9ff;
  color: #4171d6;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;
  transition: all 0.2s ease;
  position: relative;
}

.skill-pill.experience {
  padding: 4px 8px 4px 10px;
}

.skill-pill .months-experience {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border-left: 1px solid #d1dfff;
  margin-left: 6px;
  padding-left: 6px;
  font-size: 10px;
  color: #6693e8;
  font-weight: 600;
}

.skill-pill.matched {
  background-color: #f0f5ff;
  font-weight: 500;
  box-shadow: none;
}

.skill-pill:hover,
.skill-pill:focus {
  background-color: #e6eeff;
  transform: translateY(-1px);
  transition: all 0.2s ease;
  outline: none;
  border-color: #d1dfff;
}

.skill-tooltip::after {
  content: attr(title);
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  background-color: #2c4676;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  font-size: 10px;
  font-weight: 400;
}

.skill-pill:hover .skill-tooltip::after,
.skill-pill:focus .skill-tooltip::after {
  opacity: 1;
  visibility: visible;
}

.clamp {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

// AI Badge Styles
.ai-badge {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 0.4rem;
  height: 22px;
  font-size: 0.65rem;
  font-weight: 500;
  border-radius: 9999px;
  background: linear-gradient(135deg, #f3e8ff, #e0e7ff);  // More visible gradient
  border: 1px solid rgba(147, 51, 234, 0.2);
  color: #6d28d9;
  gap: 0.25rem;

  svg {
    width: 12px;
    height: 12px;
  }

}

@keyframes pulse {
  0% {
    opacity: 0.4;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.4;
    transform: scale(0.95);
  }
}


.react-select-filter__control {
  width: 225px;
  @extend %basic-input-style;
}

.react-select-reg__control {
  width: 445px;
}

.react-select__control {
  @extend %basic-input-style;
}

.react-select__control--is-focused:hover {
  @extend %basic-input-style-focused;
}

.react-select__option {
  &.react-select__option--is-selected,
  &.react-select__option--is-focused {
    background-color: $secondary-colour;
    color: $white;
  }

  &:hover {
    background: $primary-colour;
    color: $white;
  }
}

.react-select.is-invalid__control {
  border-color: $danger;
}

.react-select > * .account-management-selector__multi-value {
  cursor: pointer;
}

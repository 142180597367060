.dropzone-container {
  border: 1px solid $dark-grey;
  border-radius: 5px;
  height: 150px;
  width: 100%;
}

.dropzone-container.active {
  border: 1px dashed $primary-colour !important;
}

.dropzone-content {
  color: $header-primary-colour;
}
.dropzone-content.large {
  font-size: 18px;
  margin-top: 60px;
}

p.file-display {
  color: $misty-grey;
}

.upload-list {
  padding: 0;
}
.upload-list > li {
  list-style: none;
}

li.upload-entry.failed {
  color: $danger;
}
li.upload-entry.success {
  color: $success;
}

.CircularProgressbar {
  height: 110px;
  margin-top: 6px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: $primary-colour !important;
}

.CircularProgressbar-text {
  fill: $primary-colour !important;
}

.attachments-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -0.5em;

  .attachment-entry-wrapper {
    flex: 0 1 20%;
    margin: 0.5em;

    .attachment-entry-container {
      width: 100%;

      .attachment-entry-file {
        height: 150px;
        position: relative;

        .file-icon-trigger {
          align-items: center;
          background-color: $hover-grey;
          border-radius: 5px;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          svg {
            color: $darker-grey;
            width: 2.5em;
          }
        }

        .delete-icon {
          position: absolute;
          right: 5px;
          top: 5px;
          z-index: 1;

          svg {
            color: darken($darker-grey, 20%);
            width: 1.5em;
          }
        }

        .delete-icon:hover + .file-icon-trigger,
        .file-icon-trigger:hover {
          background-color: $darker-grey;

          svg {
            color: $hover-grey;
          }
        }
      }

      .attachment-entry-file-name {
        margin-top: 0.5em;
      }
    }
  }
}

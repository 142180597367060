.notification-menu-body {
  max-height: 500px;
  overflow-y: auto;
  top: 10px;
  width: 420px;
}

.notification-item-container {
  border-bottom: 2px solid #dbdbdb;
  padding: 0 10px 10px 10px;
}

.notification-item-main-content {
  align-items: flex-start;
  display: flex;
  max-width: 375px;
}
.notification-item-main-content > p {
  display: inline-block;
  white-space: normal;
}

.notification-dd-item.dropdown-header {
  margin-bottom: 5px;
}
.notification-dd-item.dropdown-header > p {
  font-size: 15px;
  font-weight: $font-weight-bold;
  padding: 5px 0 5px 5px;
}

.notification-dd {
  border: 0;
}
.notification-dd.dropdown-toggle {
  padding:0.5rem;
}

.notification-dd.dropdown-toggle:after {
  display: none;
}

.notification-dd.dropdown-toggle:hover,.notification-dd.dropdown-toggle:active,.notification-dd.dropdown-toggle:focus,.notification-dd.dropdown-toggle:visited {
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  color: $primary-colour !important;
}

.notification-dd.dropdown-toggle:focus {
  color: #727272 !important;
}

//.notification-menu-body > button {
//  padding-top: 2px;
//  cursor: default !important;
//}

.notification-menu-body > button:focus,.notification-menu-body > button:hover,.notification-menu-body > button:focus {
  background-color: white;
  border: none;
}

.no-notification-container > p {
  color: #707070;
  display: block;
  font-size: 13px;
  margin: auto;
  padding: 5px 0 5px 0;
  text-align: center;
  width: 90%;
}

.no-notification-container > svg {
  color: #707070;
  display: block;
  margin: auto;
  padding: 15px 0 15px 0;
  width: 50px;
}
.dropdown-header {
  border-bottom: 2px solid #dbdbdb;
  padding-left: 3px;
}

.dropdown-toggle {
  background-color: $white;
  color: $primary-colour;
  font-weight: $font-weight-bold;
}

.new-count {
  background-color: $danger;
  border: 1px solid $danger;
  border-radius: 50%;
  display: inline;
  height: 15px;
  right: 5px;
  position: absolute;
  top: 2px;
  width: 15px;
  z-index: 100;
  font-size: 8px;
  text-align: center;
}

.new-count-max > div {
  font-size:8px
}

.new-count > div {
  color: $white;
  display: block;
  font-weight: $font-weight-bold;
  line-height: 12px;
}

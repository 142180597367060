$wizard-grey: #dfe3e4;
$wizard-track-height: 5px;
$wizard-complete-background-color: $primary-colour;
$wizard-step-size: 20px;

.wiz-form {
  padding: 1.5em 1em;
}

.wiz-progress {
  align-items: center;
  display: flex;
  font-size: 0.75em;
  justify-content: space-between;
  position: relative;

  .wiz-track {
    background-color: $wizard-grey;
    border-radius: 0.25rem;
    height: $wizard-track-height;
    position: absolute;
    top: 5px;
    width: 100%;

    .progress {
      background-color: $wizard-complete-background-color;
      display: block;
      font-size: 0;
      height: $wizard-track-height;
      padding-left: $wizard-step-size;
      transition: width 1s ease-in;
      width: 0%;
    }
  }

  .wiz-step {
    position: relative;
    text-align: center;
    transform: translateY(-3px);
    width: 100%;

    span {
      background-color: $wizard-grey;
      border-radius: 50%;
      display: inline-block;
      height: $wizard-step-size;
      line-height: $wizard-step-size;
      text-align: center;
      text-transform: uppercase;
      width: $wizard-step-size;
    }

    &.is-active {
      span {
        background-color: $primary-colour;
        border-radius: 20px;
        color: $white;
        padding: 0 0.5rem;
        width: auto;
      }
    }

    &.is-complete {
      span {
        background-color: $wizard-complete-background-color;
        color: $white;
      }
    }

    &:last-child {
      text-align: right;
    }
  }

  div:nth-child(2) {
    text-align: left;
  }
}

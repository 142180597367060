.video-control {
  align-items: center;
  bottom: 0;
  display: flex;
  position: absolute;
  width: 100%;

  p {
    background: $light-grey;
    border-radius: 0.25rem;
    flex: 1 0 auto;
    padding: 0.45rem 0.75rem;
    text-align: center;
    text-transform: uppercase;
  }

  button {
    margin-left: 1rem;

    &.media-control {
      border-color: $success;
      background-color: $success;
      width: 50px;
      height: 35px;

      &.media-control-disabled {
        border-color: $danger;
        background-color: $danger;

        .mic {
          width: 1.75em;
        }
      }
    }
  }

  .camera-toggle-icon {
    width: 1.5em;
  }
}

.video-ui-wrapper {
  display: flex;
  padding-top: 1em;
  position: relative;
}

.video-wrapper {
  position: relative;
  width: 100%;
}

.video-screen {
  position: relative;
}

.participant-view {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.webcam-mainview {
  width: 100%;
}

.webcam-preview {
  box-shadow: 0 0 5px 2px rgba($black, 0.25);
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  width: 150px;
}

.video-details {
  padding-left: 1em;
  width: 40%;
}

.video-interview-details-application {
  margin-top: 1em;

  .cv-download {
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

.video-invite-status-update {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  label {
    margin-bottom: 0;
    margin-right: 0.5em;
  }
}

.video-interview-notes-wrapper {
  margin-top: 1em;
  overflow: auto;
  padding-right: 1em;
}

.video-interview-details-toggle {
  bottom: calc(20px - 0.25rem);
  color: $link;
  position: absolute;
  right: 20px;

  &::before {
    content: '<';
    display: inline-block;
    margin-right: 0.25em;
  }

  &::after {
    content: '>';
    display: none;
    margin-left: 0.25em;
  }

  &.is-open {
    &::before {
      display: none;
    }

    &::after {
      display: inline-block;
    }
  }
}

.video-interview-details-tabs {
  align-items: center;
  border-bottom: 2px solid #d8d8d8;
  display: flex;
  margin-bottom: 0;

  li.tab-item {
    border-bottom: 2px solid transparent;
    display: block;
    flex: 1 1 50%;
    margin-bottom: -2px;
    margin-right: 0;

    a {
      color: $dark-grey;
      font-size: 1rem;
      font-weight: $font-weight-bold;
    }

    &.tab-item-active {
      border-bottom-color: $header-primary-colour;

      a {
        color: $primary-colour;
      }
    }
  }
}

.form-group {
  margin-bottom: 0.5rem;

  label {
    margin-bottom: 0.5rem;
  }

  input[type=radio] {
    top: 0.3em;
    position: relative;
  }
}

legend {
  color: #5c6873;
  font-size: 1em;
  font-weight: $font-weight-bold;
}

// form overrides
.form-control {
  font-size: 0.875rem;

  height: calc(1.5em + 0.75rem + 4px);
  padding: 5px 6px 6px 6px;

  &.small,
  &.small:focus {
    border-radius: 0;
    height: auto;
    padding: 0 0.2em 0 0.5em;
  }
  @extend %basic-input-style;
}

.form-control:focus {
  @extend %basic-input-style-focused;
}

.inline-form-layout {
  margin-bottom: 1rem;
}

.uploaded-documents {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  li {
    margin-right: 20px;
    padding-left: 25px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      margin-right: 5px;
    }

    .delete-file {
      background-color: transparent;
      border: 0;
      color: $red;
      font-weight: $font-weight-bold;
      transform: rotate(45deg);

      &:focus {
        outline: none;
      }
    }
  }
}

ul.error-detail {
  list-style: none;
  padding-left: 0;
}

.required,
.required-input-label::after {
  color: $danger;
  margin-left: 2px;
}

.required-input-label::after {
  content: '*';
}

.help-block {
  color: gray;
  font-size: 12px;
  margin-top: 5px;
}

input[type=checkbox] {
  appearance: none;
  background-color: #f0f0f1;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  cursor: pointer;
  height: 18px;
  width: 18px;

  & + span {
    bottom: 4px;
    left:5px;
    margin-right:15px;
    position: relative;
  }

  &:focus {
    outline: 0;
  }

  &:checked {
    background: $primary-colour url(../../assets/img/icons/check/white.svg) no-repeat center/16px 16px;
    border: 1px solid #adb8c0;
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: $white;
  }

  &:disabled {
    background-color: #adb8c0;
  }

  &.is-indeterminate {
    appearance: auto;
  }
}

.form-switch input[type=checkbox] {
  appearance: none;
  background-color: white;
  border: 1px solid #c8ced3;
  border-radius: 15px;
  cursor: pointer;
  background-size: 1.5rem;
  height: 1.6rem;
  width: 2.5rem;

  & + span {
    bottom: 4px;
    left:5px;
    margin-right:15px;
    position: relative;
  }

  &:focus {
    outline: 0;
  }

  &:checked {
    border: 1px solid #3a9d5d;
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    background-color: #4dbd74 ;

  }
}

.single-centered-input {
  width: 30px;
  text-align: center;
}

.contract-quill-editor-container > .quill {
  height: 50vh;
  padding-bottom: 45px;
}

.signature-example-container {
  width: 250px;
  height: 50px;
  border: 1px solid #9b9b9b;
  background-color: #f1f1f1;
  margin-top: 5px;
  margin-bottom: 15px;
}

.signature-example-container > p {
  margin-top: 7px;
  font-size: 20px;
  text-align: center;
  font-family: Montserrat, SansSerif, Roboto;
}

.mentions_textarea__input {
  height: 200px;
}


.user-select {
  width: 267px;
  margin: 5px 0;
}

.user-select-option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.user-select-option:hover {
  background-color: #f2f2f2;
}

.user-select-option.selected {
  background-color: #007bff;
  color: white;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.initials {
  font-size: 16px;
  color: white;
  font-weight: bold;
}

.text {
  flex: 1 1 85%;
}

.form-modal .modal-body{
  padding: 0px 20px 0px 20px
}

.forms-modal-iframe .modal-body {
  padding:0;
}

.is-invalid.react-multi-email {
  border-color: #DE5471
}

.is-invalid.react-multi-email:focus {
  border-color: #C6CBCF
}
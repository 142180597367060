.DateInput_input {
  font-size: 14px;
  font-weight: $font-weight-normal;
  padding: 2px;
}

.SingleDatePicker_picker {
  z-index: 10000;
}

.SingleDatePicker {
  display: block;
}

.DateInput {
  display: inline-block !important;
  flex: 0 1 100%;
  width: 90px
}

.DateInput_input__focused {
  border-bottom: none;
}

.SingleDatePickerInput_calendarIcon {
  margin: 0 0 0 0 !important;
}

.SingleDatePickerInput {
  align-items: center;
  border-radius: 5.4px;
  display: flex;
  @extend %basic-input-style;
}

.DateRangePickerInput_calendarIcon {
  margin-left: 5px!important;
  padding: 10px 5px;
}

.DateRangePickerInput_arrow_svg {
  margin-right: 15px;
  margin-top: -2px;
}

.date-picker{
  display: flex;
  gap: 1rem;
  
  > div:nth-child(2){
    flex-grow: 1;
  }
}

.DateInput_fang {
  margin-top: -5px;
}
// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~bootstrap/scss/bootstrap';

// If you want to add something do it here
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import '~react-toastify/dist/ReactToastify.css';
@import '~tippy.js/dist/tippy.css';
@import '~react-sliding-pane/dist/react-sliding-pane.css';
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
// @import '~@fullcalendar/list/main.css';
// @import '~@fullcalendar/bootstrap5/main.css';
// @import '~react-table/react-table.css';
@import './components/react-table-base';
@import '~react-input-range/lib/css/index.css';
@import 'react-multi-email/dist/style.css';
@import './components/placeholders';
@import './mixins';
@import './account-vars';
@import './base';
@import './components/app-layout';
@import './components/react-table';
@import './components/dropdown-menu';
@import './components/wizard';
@import './components/attachments';
@import './components/publishing';
@import './components/forms';
@import './components/inputs';
@import './components/resize-video';
@import './components/candidate-details';
@import './components/bits-and-bobs';
@import './components/tabs';
@import './components/communication';
@import './components/funnels';
@import './components/tags';
@import './components/buttons';
@import './components/loading-screen';
@import './components/slider';
@import './components/candidate-slider';
@import './components/video';
@import './components/filters';
@import './components/reporting';
@import './components/flexiforms';
@import './components/schedule';
@import './components/swimlanes';
@import './components/interviews';
@import './components/publish-form';
@import './components/color-picker';
@import './components/detail-block';
@import './components/flow-chart';
@import './components/react-autosuggest';
@import './components/date-picker';
@import './components/popups';
@import './components/react-select';
@import './components/language-toggle';
@import './components/notification-menu';
@import './components/dashboard';
@import './components/external';
@import './components/integrations';
@import './components/timeline';
@import './components/vacancy';
@import './components/temp';
// ie fixes
@import 'ie-fix';

// Styles
@tailwind base;
@tailwind components;
@tailwind utilities;

// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';

@import '../node_modules/react-dates/lib/css/_datepicker.css';

// Import Main styles for this application
@import './scss/style.scss';



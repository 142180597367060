// quill rte input
.ql-video-wrapper {
  position: relative;

  .ql-video-controls {
    background-color: rgba(230, 230, 230, 0.8);
    cursor: pointer;
    display: flex;
    left: 0;
    position: absolute;
    top: -8px;

    .ql-video-size-controls {
      padding: 4px;

      span {
        margin-right: 4px;
      }

      select {
       -webkit-appearance: menulist;
      }
    }
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  background-clip: padding-box;
  background-color: $white;
  color: #5c6873;
  display: block;
  font-size: 0.875rem;
  height: calc(2.0625rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  @extend %basic-input-style;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  background-color: $white;
  border: 1px solid #aaa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: block;
  position: absolute;
  top: 35px;
  width: 100%;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestion {
  color: #5c6873;
  cursor: pointer;
  padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $primary-colour;
  color: $white;
}

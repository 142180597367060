.dropdown-menu {
  background-clip: padding-box;
  background-color: $white;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
  min-width: 6rem;
  padding: 0 0;
}

.dropdown-item {
  color: $primary-colour;
  font-size: 0.9rem;
  font-weight: $font-weight-normal;
  padding: 10px;
  position: relative;
  // text-align: center;

  &:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }

  &:last-child {
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px);
  }

  i {
    color: #c8ced3;
    display: inline-block;
    // margin-left: -10px;
    margin-right: 5px;
    text-align: center;
    width: 20px;
  }
}

.dd-menu {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba($black, 0.15);
  margin-top: 5px;
  user-select: none;
  width: 225px;
}

.dd-menu-absolute {
  position: absolute;
}

.dd-menu-header {
  border-bottom: 1px solid #d8d8d8;
  height: 40px;
}

.dd-menu-header > p {
  color: $header-primary-colour;
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 40px;
  margin-left: 10px;
}

.floating-pop-over {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba($black, 0.15);
  margin-top: 10px;
  position: absolute;
  width: 225px;
  z-index: 2;
}

.middle {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dd-menu-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  position: relative;
  width: 100%;

  &:hover {
    background-color: $hover-grey;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  svg {
    color: $primary-colour;
    margin-left: 10px;
  }

  span {
    color: $primary-colour;
    margin-left: 10px;
  }

  &.is-disabled {
    opacity: 0.4;
  }
}

.icon-add-plus {
  position: relative;
  top: -2px;
}

.action-text {
  cursor: pointer;
}

.action-text > span {
  color: $header-primary-colour;
  display: inline-flex;
  font-size: 1em;
  font-weight: $font-weight-bold;
  line-height: 0;
  margin: 0 0 0 5px;
}

.app-form-item-body {
  background-color: #fafbff;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba($black, 0.15);
  margin-top: 15px;
  min-height: 65px;
  padding-bottom: 2px;
  width: 100%;
  z-index: 10000;
}

.app-form-item-body-label {
  color: #48525a;
  font-size: 16px;
  padding: 5px 0 0 10px;
  z-index: 10000;
}

.CalendarDay__today {
  border:2px solid $primary-colour;
}

.btn-save-filter {
  width: 100%;
}

select:-moz-focusring {
   color: transparent !important;
   text-shadow: 0 0 0 $black;
 }

select {
  -webkit-appearance:none;
}

option:not(:checked) {
  color: #5c6873;
}

.default-filter {
  margin-top: .5rem;
}

// Multiselect
.react-select__multi-value,
.react-select__multi-value__label,
.react-select__multi-value__remove:hover {
  background-color: $primary-colour !important;
  color: $white !important;
}

.react-select__multi-value__remove {
  cursor: pointer;
}

.is-invalid {
  .react-select__control {
    border-color: $danger;
  }
}

.no-active-bg:active {
  background-color: #f8f9fa !important;
  color: inherit !important;
}

.notif-action-icon {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.export-options-menu {
  width: 300px;
  padding-bottom: 10px;
}

.export-menu-container {
  margin-left: 1px;
}

.export-menu-item {
  height: 27px;
  width: 130px;
}

.download-options-menu {
  width: 300px;
  padding-bottom: 10px;
}


.export-menu-item {
  height: 27px;
  width: 130px;
}

.free-text-action-btn {
  padding: 10.5px 10.5px !important;
}

.free-text-action-btn + div > .icon-button {
  padding: 10.5px 12.5px !important;
}
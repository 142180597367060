$default-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$work-sans: 'Work Sans', sans-serif;

$light-grey: #E8E8E8;
$dark-grey: #afafaf;
$hover-grey: #F0F1F5;
$bg-grey: #F3F4F8;
$misty-grey: #A4A9BE;
$darker-grey: #647A8D;

/*
$lighter-grey
$light-grey
$grey
$dark-grey
$darker-grey
$hover-grey
*/

$white: #FFFFFF;
$black: #111111;
$red: #DE5471;
$orange: #EC9232;
$hazy-orange: #da8b40;
$purple: #6F08B8;
$yellow: #E8C100;
$green: #4DBD74;
$teal: #4FE1C2;
$dark-green: #526E38;
$blue: #5285EF;
$dark-blue: #1F425E;

//$primary-colour: #283540;
$primary-colour: #5A5A5A;
$secondary-colour:#1A2E56;
$header-primary-colour: $secondary-colour;
$header-secondary-colour: $dark-grey;
$action-colour:#5285EF;
$body-background: $bg-grey;
$slider-background: #EAEDF4;
$slider-header-background: #F5F6FA;

$calender-event-background-colour: #3788D8;
$calender-event-partially-booked-background-colour: $orange;
$calender-event-fully-booked-background-colour: $green;

$primary: $primary-colour;
$success: $green;
$warning: $orange;
$danger: $red;
$info: $primary-colour;
$link: $action-colour;

$font-weight-bolder: 600;
$font-weight-bold: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

/*
Overriding the BootstrapV5 variables
*/
$grid-gutter-width: 2.15em;
$card-spacer-y : 1.45em;
$card-spacer-x : 1.45em;
$border-radius: 0.25rem;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
);

.fc {
  button.fc-button {
    padding: 0.375rem 0.75rem;

    &.fc-next-button,
    &.fc-prev-button{
      padding: 0.375rem 0.375rem;
    }
    .fc-icon{
      font-weight: 700;
    }
  }

  .fc-header-toolbar {
    .fc-button-primary {
      background-color: $primary-colour;
      border-color: $primary-colour;
      color: $white;

      &:not(:disabled):not(.disabled).active {
        background-color: darken($color: $primary-colour, $amount: 10);
        border-color: darken($color: $primary-colour, $amount: 10);
      }

      &:not(:disabled).fc-button-active{
        background-color: #414141;
        border-color: #414141;
      }

      &:hover:not(:disabled).fc-button-active,
      &:focus:not(:disabled).fc-button-active,
      &:hover {
        background-color: $white;
        border: 1px solid #c6cbcf;
        color: #1a2e56;
      }

      &:disabled{
        background-color: rgba(90,90,90,.3);
        border-color: rgba(90,90,90,.3);
      }
      &:disabled:hover {
        background-color: #676767;
        border-color: #676767;
        color: $white;
      }
      &:disabled:hover {
        background-color: #676767;
        border-color: #676767;
        color: $white;
      }
    }
  }

  a {
    color:rgb(90,90,90)
  }
}

.fc-event-container {
  cursor: pointer;
}

.fc-scrollgrid-sync-inner a {
  color: $primary-colour;
}

#location_address__title {
  display: none;
}

.description-preview {
  position: relative;

  &::before {
    background-color: $white;
    color: #5c6873;
    content: 'Preview';
    font-size: 0.75rem;
    left: 7px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    position: absolute;
    top: -8px;
  }

  & > div {
    max-height: 100px;
    overflow-y: auto;
  }
}

.fc-list-event-title {
  position: relative;
}

.fc-controls {
  cursor: pointer;
  display: none;
  position: absolute;
  right: 0;
  top: 0;

  .event-btn {
    background-color: rgba($white, 0.8);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border-radius: 2px;
    display: inline-block;
    height: 16px;
    margin-left: 3px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 16px;
  }

  .event-ics {
    background-image: url(../../assets/img/icons/save/primary.svg);
    margin-right: 3px;
  }

  .event-view {
    background-image: url(../../assets/img/icons/eye/primary.svg);
    margin-left: 0;
  }

  .event-edit {
    background-image: url(../../assets/img/icons/pencil-alt/primary.svg);
  }

  .event-delete {
    background-image: url(../../assets/img/icons/bin/primary.svg);
  }

  .event-copy {
    background-image: url(../../assets/img/icons/copy/primary.svg);
  }

  &.list-view {
    display: block;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    .event-btn {
      background-size: 15px 15px;
      margin-right: 5px;
    }

    .event-video {
      background-image: url(../../assets/img/icons/video/black.svg);
    }

    .event-ics {
      background-image: url(../../assets/img/icons/save/black.svg);
    }

    .event-view {
      background-image: url(../../assets/img/icons/eye/black.svg);
    }

    .event-edit {
      background-image: url(../../assets/img/icons/pencil-alt/black.svg);
    }

    .event-delete {
      background-image: url(../../assets/img/icons/bin/black.svg);
      margin-right: 0;
    }
  }
}

.event-hover {
  .fc-controls {
    display: block;
  }
}

.read-label {
  font-weight: $font-weight-bold;
  margin-bottom: 0;
  margin-right: 10px;
  width: 200px;
}

.candidate-status {
  padding-bottom: 0.5em;

  .candidate-toggle {
    color: $header-primary-colour;
    cursor: pointer;
    font-weight: $font-weight-bold;
    user-select: none;

    &:focus {
      outline: none;
    }

    .candidate-status-label {
      margin-right: 5px;
      min-width: 95px;
    }
  }

  .candidate-toggle-pane {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out;

    > div {
      margin: 0.5em 0.25em 0.75em 1.75em;
    }

    .candidate-list {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0.5em;
    }

    input[type=checkbox] + span {
      bottom: -2px;
    }

    .candidate-select {
      flex: 0 0 33%;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
}
.interview-form {
  .sms-form-group {
    flex: 0 1 30%;
  }

  .reminder-form-group {
    flex: 0 1 70%;
  }
}

.event-input-radio {
  input[type=radio] {
    top: 0;
    position: relative;
    margin-top:0;
  }
  input[type=radio] + span {
    top: 0;
  }
}

.sync-calendar-container {
  position: relative;
  display: inline-block;
}

.sync-calendar-button {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    margin: 0 8px;
  }

  .chevron {
    transition: transform 0.3s ease;

    &.open {
      transform: rotate(180deg);
    }
  }
}

.sync-calendar-container {
  position: relative;
  display: inline-block;
}

.sync-calendar-button {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 15px;

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    margin-left: 8px;
  }
}

.sync-calendar-dropdown {
  position: absolute;
  top: 100%;
  right: 0px;
  width: 250px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 10;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &.synced {
    background-color: #e8f5e9;
  }

  .sync-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    flex-grow: 1;
    font-size: 14px;
    color: #333;

    .service-logo {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    .check-icon {
      margin-left: auto;
      color: #4caf50;
    }
  }

  .unsync-button {
    background: none;
    border: none;
    color: #f44336;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #ffebee;
    }
  }
}

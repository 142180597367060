.publish-form {
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.publish-form > * label {
  display: flex;
  align-items: center;
  margin-left: 2px;
  margin-bottom: 2px;
}

.publish-form > * input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
.publish-form > * .form-group {
  display: inline-block;
  margin-right: 25px;
  vertical-align: top;
}

.publish-form > * .form-group-block {
  display: block;
}

.publish-form > * .field-object {
  width: 100% !important;
}

.publish-form > * legend {
  margin-left: 2px;
  margin-bottom: 0;
  font-size: 16px;
}

.publish-form > * .react-select__control {
  min-width: 150px;
}

.publish-form > * .publish-select {
  min-width: 440px;
}

.publish-form > * .publish-text > input[type=text]{
  min-width: 440px;
}

.publish-form > * input[type=checkbox] {
  -webkit-appearance: none;
  background-color: #f0f0f1;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  cursor: pointer;
  height: 18px;
  width: 18px;

  & + span {
    bottom: 4px;
    left:5px;
    margin-right:15px;
    position: relative;
  }

  &:focus {
    outline: 0;
  }

  &:checked {
    background: $primary-colour url(../../assets/img/icons/check/white.svg) no-repeat center/16px 16px;
    border: 1px solid #adb8c0;
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: $white;
  }
}

.publish-form > * .checkbox > label > span {
  margin-top: 7px;
}

.w-50 {
  width: 50%;
  input {
    width: 100%;
  }
}

.w-33 {
  width: 30%;
  input {
    width: 100%;
  }
}

.w-25 {
  width: 22%;
  input {
    width: 100%;
  }
}

.publish-btn {
  margin-right: -15px;
  margin-bottom: -10px;
}

.publish-form .form-group.basic-multiposter-detail-container,
.publish-form .form-group.basic-multiposter-additional-container  {
  margin-right: 0;
}

.basic-multiposter-detail-container,.basic-multiposter-additional-container {
  border: 1px solid #d4d4d4;
  padding: 10px;
}

.basic-multiposter-detail-container > fieldset > legend, .basic-multiposter-additional-container > fieldset > legend {
  font-size: 20px;
  margin-bottom: 10px;
  color: $header-primary-colour;
}

.basic-multiposter-detail-container > label {
  display: none;
}


.basic-multiposter-additional-container > * > .publish-select {
  margin-right: 0;
}





.color-picker-swatch {
  background: $white;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba($black, 0.1);
  cursor: pointer;
  display: inline-block;
  padding: 5px;

  > div {
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba($black, 0.1);
    height: 14px;
    width: 36px;
  }
}

.color-picker {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 10000;
}

.color-picker-addon {
  background-color: #e9ecef;
  border: 1px solid #c6cbd0;
  border-bottom-right-radius: 5.4px;
  border-left: none;
  border-top-right-radius: 5.4px;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.38em 0.5em;
  margin-top: -1px;
}


.reporting-side-bar-title {
  color: $header-primary-colour;
  font-size: 1.15em;
  font-weight: $font-weight-bold;
}

.reporting-tooltip-container {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #5d5d5d;
}

ul.reporting-nav {
  li {
    margin: 0.5em 0;
    overflow: hidden;

    a {
      background-color: #FFFFFF;
      border-radius: 6px;
      color: $primary-colour;
      display: block;
      padding: 0.5em 0.5em;

      &:hover {
        background-color: $secondary-colour;
        color: $white;
        text-decoration: none;
      }
    }

    &.active {
      a {
        background-color: $secondary-colour;
        color: $white;
      }
    }
  }
}

.reporting-filters {
  .account-opts {
    margin-bottom: 0;
  }

  .SingleDatePickerInput,
  .DateRangePickerInput {
    border-radius: 4px;
    height: 38px;
    margin-right: 1em;
  }

  .DateRangePickerInput {
    width: 16.5rem;
  }

  .reporting-select {
    min-width: 200px;
    margin-bottom: 0 !important;
  }

  .reporting-filter {
    margin-right: 1em;
  }
}

.reporting-charts {
  .reporting-card {
    margin-top: 1em;

    .card-body {
      padding: 0;

      h4 {
        margin-bottom: 1em;
        margin-left: 2em;
        margin-top: 1em;
      }
    }

    &.ref-bar-chart {
      h4 {
        margin-left: 0.5em;
      }
    }
  }

  .reporting-stat-card {
    margin-top: 1em;
  }
}

.reporting-stat-card {
  .card-body {
    min-height: 192px;

    h6 {
      color: $primary-colour;
      font-size: 0.85em;
      font-weight: $font-weight-normal;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .number-stat {
    color: $secondary-colour;
    font-size: 2em;
    font-weight: $font-weight-bold;
    text-align: center;

    a {
      color: $secondary-colour;
    }
  }

  .single-number-stat {
    margin-top: 25px;
    font-size: 4em;
  }

  .period-text {
    font-size: 0.85em;
    font-weight: $font-weight-light;
    text-align: center;
  }

  .percentage-display {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    top: 2em;
  }

  .percentage-arrow {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    height: 0;
    margin-right: 0.75em;
    width: 0;

    &.positive, {
      border-bottom: 15px solid $success;
    }

    &.negative, {
      border-top: 15px solid $danger;
    }

    &.neutral {
      border-left: 0;
      border-right: 0;
    }

    &.positive.red, {
      border-bottom: 15px solid $danger;
    }

    &.negative.green, {
      border-top: none;
      border-bottom: 15px solid $success;
    }
  }

  .percentage-text {
    font-size: 1.25em;

    &.positive {
      color: $success;
    }

    &.negative {
      color: $danger;
    }

    &.neutral {
      color: $warning;
    }

    &.positive.red, {
      color: $danger;
    }

    &.negative.green, {
      color: $success;
    }
    &.na {
      color: $warning;
      font-weight: $font-weight-bold;
    }
  }
}

.dashboard-number-stat-card {
  display: inline-block;
  height: 100px;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 175px;
}

.dashboard-number-stat-center {
  bottom: auto;
  left: auto;
  position: relative;
  top: auto;
}

.total-text-card-container {
   display: inline-block;
   height: 100px;
   margin: 4px;
   width: 350px;
 }

 .no-data-container {
   align-items: center;
   display: flex;
   height: 100%;
   justify-content: center;
 }

 .report-chart-change-btn {
   background-color: transparent;
   border-color: transparent;
   border: none !important;
   color: #0c0d0d;
   padding: 5px;
   font-size: 15px;
   margin: 0;
 }

 .report-chart-change-btn:hover,.report-chart-change-btn:active,.report-chart-change-btn:focus-visible,.report-chart-change-btn:focus {
   border: none !important;
   color: #676969 !important;
 }

.report-chart-change-btn.active,.report-chart-change-btn.active:active,.report-chart-change-btn.active:focus,.report-chart-change-btn.active:hover {
  background-color: transparent !important;
  color: #68b6b6 !important;
  box-shadow: none !important;
}

// .spacing-line {
//   border-right: 1px solid;
//   height: 100%;
// }

.dashboard-scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}

.react-select.dashboard__control {
  height: 30px;
  width: 60px;
}
.react-select.dashboard__indicator {
  padding: 0px;
  width: 20px;
}

.overdue-header {
  color: $header-primary-colour;
  font-size: 16px;
  margin-left:10px;
  margin-right:10px;
}


.inline-filter-select {
  width: 250px;
  display: inline-block;
}

.treemap-custom-tooltip {
  position: relative;
  display: inline-block;
}
.treemap-custom-tooltip > p {
  visibility: visible;
  width: 250px;
  background-color: #626262;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
}

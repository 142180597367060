.interview-invite {
  border: 1px solid $dark-grey;
  border-radius: 5px;
  margin-top: 1em;
  padding: 1em;
}

.interview-notes-wrapper {
  border-top: 1px solid $dark-grey;
  margin-top: 1em;
  padding-top: 1em;
}

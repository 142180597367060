@mixin default-colors {
  background-color: $primary-colour;
  border: 1px solid $primary-colour;
  color: $white;
}

@mixin outline-colors {
  background-color: $white;
  border: 1px solid $dark-grey;
  color: $secondary-colour;

  svg {
    color: $secondary-colour;
  }
}

.btn {
  font-size: 0.875rem;

  &:hover:not(.disabled):not(:disabled):not(.btn-link):not(.btn-outline),
  &:focus:not(.disabled):not(:disabled):not(.btn-link):not(.btn-outline) {
    @include outline-colors;
  }

  // BEGIN
  // Needs o be removed when competencies updated
  &.plaintext {
    color: $header-primary-colour;
    padding: 0;

    &:hover,
    &:focus {
      color: $header-primary-colour;
      text-decoration: none;
    }
  }
  // END

  &:focus {
    box-shadow: none;
  }

  &.xs {
    line-height: 1.1;
  }

  &.disabled,
  &:disabled {
    &:not(.btn-link) {
      background-color: rgba($primary-colour, 0.3);
      border-color: rgba($primary-colour, 0.3);
    }

    color: rgba($secondary-colour, 0.5);
  }
}

.btn-sm,
.btn-group-sm > .btn {
  border-radius: 0.2rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}

.btn-default {
  @include default-colors;
}

.btn-outline {

  &:hover,
  &:focus {
    @include default-colors;
  }
  @include outline-colors;

  // &.grey {
  //   background-color: $bg-grey;
  // }
}

button.float-right + button {
  margin-right: 0.75em;
}

.btn-action-cog {
  svg {
    color: $action-colour;
    transform: translate(-3px, -2px);
    width: 1.4em;
  }

  &.no-text {
    border-radius: 5px;
    padding: 5px;

    svg {
      transform: translate(0, 0);
    }
  }

  &.paper-plane {
    svg {
      transform: translateY(-1px);
      width: 1.2em;
    }
  }

  &:hover {
    svg {
      // color: $white;
    }
  }

  &.is-disabled {
    opacity: 0.5;

    &:hover {
      svg {
        color: $action-colour;
      }
      @include outline-colors;
    }
  }

  &.ellipsis-opts-btn {
    padding-left: 1em;
    padding-right: 1em;

    svg {
      color: $primary-colour;
    }

    &:hover,
    &:focus {
      svg {
        color: $hover-grey;
      }
    }
  }
}

.btn-undo {
  background: url(../../assets/img/icons/undo/white.svg) no-repeat center center;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.btn-comments-icon {
  background-color: transparent;
  background-image: url(../../assets/img/icons/comments/primary.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  display: inline-block;
  height: 1rem;
  vertical-align: text-bottom;
  width: 1rem;

  &.white {
    background-image: url(../../assets/img/icons/comments/white.svg);
  }

  &.black {
    background-image: url(../../assets/img/icons/comments/black.svg);
  }
}

.btn-chevron-toggle {
  height: 1.5em;
  transition: transform 0.25s ease-in-out;
  width: 1.5em;

  &.toggle-up {
    transform: rotate(-180deg);
  }

  &.toggle-right {
    transform: rotate(-90deg);
  }
}

.btn-loading-icon {
  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
  width: 1rem;

  &.white {
    color: $white;
  }

  &.black {
    color: $black;
  }

  &.action {
    color: $action-colour;
  }

  &.grey {
    color: $primary-colour;
  }
}

.btn-heading {
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: none;
  }
}

i.fa-btn {
  margin-right: 10px;
}

button.close {
  background-color: initial;
  border: 0;
  padding: 0;
  color: #111;
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  opacity: .5;
  text-shadow: 0 1px 0 #fff;
}

button.close:focus {
  border: none;
  outline: none;
}

.icon-button {
  svg {
    margin-right: 0.5em;
  }
}

.pagination-btn {
  &::before,
  &::after {
    display: inline-block;
  }

  &.pagination-prev-btn {
    &::before {
      content: '<';
      margin-right: 0.25em;
    }
  }

  &.pagination-next-btn {
    &::after {
      content: '>';
      margin-left: 0.25em;
    }
  }
}

.btn-link {
  text-decoration: none;
}

.btn-danger {
  background-color: #de5471;
  border-color: #de5471;
  color: #fff;
}
.ReactTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;

  * {
    box-sizing: border-box;
  }

  .rt-table {
    align-items: stretch;
    border-collapse: collapse;
    display: flex;
    flex: auto 1;
    flex-direction: column;
    overflow: auto;
    width: 100%;
  }

  .rt-thead {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    user-select: none;

    &.-headerGroups {
      background: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    &.-filters {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      input,
      select {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        font-size: inherit;
        font-weight: normal;
        outline-width: 0;
        padding: 5px 7px;
      }

      .rt-th {
        border-right: 1px solid rgba(0, 0, 0, 0.02);
      }
    }

    &.-header {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .rt-tr {
      text-align: center;
    }

    .rt-th,
    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: inset 0 0 0 0 transparent;
      line-height: normal;
      padding: 5px 5px;
      position: relative;
      transition: box-shadow .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      &.-sort-asc {
        box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
      }

      &.-sort-desc {
        box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
      }

      &.-cursor-pointer {
        cursor: pointer;
      }

      &:last-child {
        border-right: 0;
      }
    }

    .rt-th:focus {
      outline-width: 0;
    }

    .rt-resizable-header {
      overflow: visible;

      &:last-child {
        overflow: hidden;
      }
    }

    .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rt-header-pivot {
      border-right-color: #f7f7f7;

      &::after,
      &::before {
        border: solid transparent;
        content: " ";
        height: 0;
        left: 100%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        width: 0;
      }

      &::after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #fff;
        border-width: 8px;
        margin-top: -8px;
      }

      &::before {
        border-color: rgba(102, 102, 102, 0);
        border-left-color: #f7f7f7;
        border-width: 10px;
        margin-top: -10px;
      }
    }
  }

  .rt-tbody {
    display: flex;
    flex: 99999 1 auto;
    flex-direction: column;
    overflow: auto;

    .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);

      &:last-child {
        border-bottom: 0;
      }
    }

    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.02);

      &:last-child {
        border-right: 0;
      }
    }

    .rt-expandable {
      cursor: pointer;
      text-overflow: clip;
    }
  }

  .rt-tr-group {
    align-items: stretch;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .rt-tr {
    display: inline-flex;
    flex: 1 0 auto;
  }

  .rt-th,
  .rt-td {
    flex: 1 0 0;
    overflow: hidden;
    padding: 7px 5px;
    text-overflow: ellipsis;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    white-space: nowrap;

    &.-hidden {
      border: 0 !important;
      min-width: 0 !important;
      opacity: 0 !important;
      padding: 0 !important;
      width: 0 !important;
    }
  }

  .rt-expander {
    color: transparent;
    display: inline-block;
    margin: 0;
    margin: 0 10px;
    position: relative;

    &::after {
      border-left: 5.04px solid transparent;
      border-right: 5.04px solid transparent;
      border-top: 7px solid rgba(0, 0, 0, 0.8);
      content: '';
      cursor: pointer;
      height: 0;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      width: 0;
    }

    &.-open::after {
      transform: translate(-50%, -50%) rotate(0);
    }
  }

  .rt-tr:hover .resizer {
    border-right: rgb(226, 226, 226) 2px solid;
  }

  .resizer {
    width: 15px;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: col-resize;
  }

  .resizer.isResizing {
    border-right: rgb(66, 66, 66) 2px solid;
    opacity: 0.8;
  }

  .rt-tfoot {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05);

      &:last-child {
        border-right: 0;
      }
    }
  }

  &.-striped .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03);
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .-pagination {
    align-items: stretch;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3px;
    z-index: 1;

    input,
    select {
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size: inherit;
      font-weight: normal;
      outline-width: 0;
      padding: 5px 7px;
    }

    .-btn {
      appearance: none;
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      display: block;
      font-size: 1em;
      height: 100%;
      outline-width: 0;
      padding: 6px;
      transition: all .1s ease;
      width: 100%;

      &[disabled] {
        cursor: default;
        opacity: .5;
      }

      &:not([disabled]):hover {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
      }
    }

    .-previous,
    .-next {
      flex: 1;
      text-align: center;
    }

    .-center {
      align-items: center;
      display: flex;
      flex: 1.5;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 0;
      text-align: center;
    }

    .-pageInfo {
      display: inline-block;
      margin: 3px 10px;
      white-space: nowrap;
    }

    .-pageJump {
      display: inline-block;

      input {
        text-align: center;
        width: 70px;
      }
    }

    .-pageSizeOptions {
      margin: 3px 10px;
    }
  }

  .rt-noData {
    background: rgba(255, 255, 255, 0.8);
    color: rgba(0, 0, 0, 0.5);
    display: block;
    left: 50%;
    padding: 20px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    z-index: 1;
  }

  .-loading {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    display: block;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease;
    z-index: -1;

    > div {
      color: rgba(0, 0, 0, 0.6);
      display: block;
      font-size: 15px;
      left: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-52%);
      transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      width: 100%;
    }

    &.-active {
      opacity: 1;
      pointer-events: all;
      z-index: 2;

      > div {
        transform: translateY(50%);
      }
    }
  }

  .rt-resizing {
    .rt-th,
    .rt-td {
      cursor: col-resize;
      transition: none !important;
      user-select: none;
    }
  }
}

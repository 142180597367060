$soft-green: #4ade80;
$even-softer-green: #5eead4;
$soft-red: #f87171;
$calm-blue: #93c5fd;
$multi-grey: #888888;
$muted-orange: #fdba74;
$pale-yellow: #ffe566;
$deep-red: #e76a37;
$cool-gray: #9ca3af;

// Status colors
$status-received: $soft-green;
$status-part-received: $even-softer-green;
$status-not-requested: #8491a4;  // A neutral slate blue
$status-requested: $calm-blue;
$status-multi: $multi-grey;
$status-failed: $muted-orange;
$status-reminder-1-sent: $pale-yellow;
$status-referees-requested: $pale-yellow;
$status-reminder-2-sent: $deep-red;
$status-withdrawn: $cool-gray;

// Text colors
$dark-text: rgba(0, 0, 0, 0.8);
$light-text: #ffffff;


ul.tab-list {
  list-style: none;
  padding: 0;

  &.absolute {
    left: 0;
    position: absolute;
    top: 10px;
  }

  @media (max-width: 992px) {
    &.absolute {
      left: 0.7em;
    }
  }
}

li.tab-item,
li.group-tab-item {
  display: inline-block;
  margin: 0 20px 0 0;
  padding: 3px 0 10px;

  > a {
    align-items: center;
    color: $primary-colour;
    display: flex;
    text-align: center;
    justify-content: space-between; /* Added to push badge to the right */
    width: 100%; /* Ensure the anchor takes full width */

    svg {
      margin-right: 0.5em;
      width: 1.25em;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &.tab-item-active {
    border-bottom: 2px solid $header-primary-colour;
    > a {
      color: $header-primary-colour;
    }
  }

  /* Vertical Tabs */
  &.is-vertical {
    display: block;
    margin: 0 0 5px 0;

    a {
      align-items: center;
      display: flex;
      position: relative;
      text-align: left;

      .badge {
        float: right;
        line-height: initial;
        margin-left: auto;
        margin-right: 10px;
        padding: 2px 8px;
        border-radius: 12px;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;

        .align-text {
          position: relative;
          align-items: end;
        }



        &.status_received {
          background-color: $status-received;
          color: $dark-text;
        }
        &.status_part_received {
          background-color: $status-part-received;
          color: $dark-text;
        }
        &.status_not_requested {
          background-color: $status-not-requested;
          color: $light-text;
        }
        &.status_requested {
          background-color: $status-requested;
          color: $dark-text;
        }
        &.status_multi {
          background-color: $muted-orange;
          color: $dark-text;
        }
        &.status_failed {
          background-color: $status-failed;
          color: $dark-text;
        }
        &.status_reminder_1_sent,
        &.status_referees_requested {
          background-color: $status-reminder-1-sent;
          color: $dark-text;
        }
        &.status_reminder_2_sent {
          background-color: $status-reminder-2-sent;
          color: $light-text;
        }
        &.status_withdrawn {
          background-color: $status-withdrawn;
          color: $light-text;
        }

      }
    }

    svg {
      color: $misty-grey;
      margin-left: -0.65em;
      margin-right: 0;
    }

    &.tab-item-active {
      border-bottom: none;
      > a {
        font-weight: $font-weight-bold;
      }
      svg {
        color: $action-colour;
      }
    }

    a {
      margin-right: auto;
    }
  }
}

li.group-tab-item {
  &.is-vertical {
    .group-items {
      display: none;
      margin-left: 1em;
      padding-left: 0;
      list-style: none;

      &.expanded {
        display: block;
      }

      .group-item {
        margin: 5px 0;
        padding: 0;

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.25em 0.5em;
          color: #5c6873;
          text-decoration: none;

          &:hover {
            background-color: rgba($slider-header-background, 0.1);
            border-radius: 0.25rem;
          }

          span {
            &:first-child {
              // Label text
              font-weight: normal;
            }
          }

          .badge {
            float: right;
            line-height: initial;
            margin-left: auto;
            padding: 2px 8px;
            border-radius: 12px;
            font-size: 11px;
            font-weight: 800;
            text-transform: uppercase;

            .align-text {
              position: relative;
              border-radius: 11px;
            }
          }
        }

        &.tab-item-active {
          background-color: rgba($slider-header-background, 0.5);
          border-radius: 0.25rem;
        }
      }
    }

    > a {
      font-weight: $font-weight-bold;

      .group-chevron {
        margin-left: auto;
      }
    }

    &.tab-item-active {
      > a {
        color: $header-primary-colour;
      }
    }
  }
}

.tab-row {
  border-bottom: 2px solid #d8d8d8;
  height: 55px;
  margin: 0 2px;
  padding: 0 0;
}

ul.tab-list.vacancy-details-tabs,
ul.tab-list.scheduler-settings-tabs,
ul.tab-list.email-settings-tabs {
  padding-top: 0.7em;

  li.tab-item,
  li.group-tab-item {
    > a {
      font-size: 20px;
    }
  }
}

ul > li.group-item {
  padding: 5px;
}

$static-stage-width: 60px;
$stage-height: 60px;
$drag-stage-height: $stage-height;
$drag-stage-border-radius: 5px;
$drop-zone-padding: 10px;

// https://codepen.io/stevemckinney/pen/WvWrRX
.funnel-stages,
.stages-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.funnel-stages {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.scroll-stages {
  height: $stage-height;
  left: calc(#{$static-stage-width} + (#{$drop-zone-padding} / 2));
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  top: 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.stage-label {
  display: block;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
  text-transform: capitalize;
  user-select: none;

  &.drop-label {
    left: 0;
    line-height: 1.2;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.drag-stage {
  background-color: $secondary-colour;
  border-radius: $drag-stage-border-radius;
  color: $white;
  height: $drag-stage-height;
  line-height: $drag-stage-height;
  position: relative;
  text-align: center;
  user-select: 'none';
  z-index: 2;

  &::before,
  &::after {
    content: '\22EE';
    font-size: 1.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    left: 5px;
  }

  &::after {
    right: 5px;
  }

  .stage-label {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    span {
      &:first-child {
        display: block;
      }

      &:last-child {
        color: $teal;
        display: block;
        font-size: 0.85em;
        margin-top: 0.25em;
      }
    }
  }
}

.stage {
  flex-grow: 0;
  flex-shrink: 0;
  height: $stage-height;
  position: relative;
  .stage-dropzone {
    background-color: $hover-grey;
    border-radius: $drag-stage-border-radius;
    height: $drag-stage-height;
    margin: 0 auto;
    position: relative;

    &.is-dragging {
      background-color: $yellow;
    }
  }

  &::before {
    align-items: center;
    bottom: 0;
    content: attr(data-stage-index);
    display: flex;
    font-size: 4em;
    justify-content: center;
    left: 0;
    opacity: 0.2;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &.static-stage {
    flex-basis: $static-stage-width;
    width: $static-stage-width;

    .stage-dropzone {
      background-color: lighten($red, 30%);
      width: $static-stage-width;

      &.is-dragging {
        background-color: $yellow;
      }
    }

    .stage-label {
      color: rgba($red, 0.6);

      svg {
        width: 1.8em;
      }
    }

    &::before {
      display: none;
    }

    &.is-current {
      .stage-label {
        span {
          &:first-child {
            display: none;
          }
        }

        svg {
          color: $white;
        }
      }
    }
  }
}

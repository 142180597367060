.react-flow__nodes .react-flow__node-input {
  border-color: green;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 5px;
}

.react-flow__nodes .react-flow__node-input.selectable:hover {
  box-shadow: 0 1px 1px 0 darkgreen;
}

button.has-error span {
  color: darkred;
}

.react-select [id^="react-select-"] {
  max-height: 160px;
}

.react-select-lower [id^="react-select-"] {
  max-height: 115px;
}

.react-flow__node-AnatationNode {
  border-radius: 0;
  text-align: left;
  background: rgba(255, 255, 255, 0.5) !important;
  border: dashed 2px #ccc !important;
  line-height: 1.4;
  width: 225px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  }
  
  .react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
  
  .react-flow__node.notes {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  }
  
  .react-flow__node.notes .react-flow__handle {
    display: none;
  }
  
  .custom-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .custom-node__body {
    padding: 10px;
  }
  
  .custom-node__select {
    position: relative;
    margin-bottom: 10px;
  }
  
  .custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
  }
  

  .dndflow {
    display: flex;
    justify-content: space-between;
  }
  
  .description {
    padding: 10px;
    color: #fff;
    background: #333;
    border-radius: 3px;
    margin-bottom: 5px;
  }
  
  .dndnode {
    text-align: left;
    font-size: 12px;
    color: black;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    line-height: 32px;
  }
  
  .dndnode .fa {
    margin-top: auto;
    margin-bottom: auto;
  }


  .react-flow__node.react-flow__node-notesNode {
    background: #fff;
  }
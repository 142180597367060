.tag-wrapper {
  display: flex;
  flex-wrap: wrap;

  .tag {
    background-color: #f3f3f3;
    border-radius: 0.28571429rem;
    color: rgba($black, 0.6);
    font-size: 0.8rem;
    line-height: 1.25;
    margin: 0.25em 0.5em 0.25em 0;
    padding: 0.5em 0.8em;
    user-select: none;

    &.is-error {
      background-color: rgba($danger, 0.5);
    }

    &:first-child {
      margin-left: 0;
    }

    & span {
      cursor: pointer;
      margin-left: 0.833em;
    }
  }
}

.comms-email-form {
  .is-hidden {
    display: none;
  }

  div.form-control {
    background-color: $white;
    border: none;
    box-shadow: none !important;
    height: auto;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;

    &.is-empty {
      padding: 0;
    }
  }

  .form-control[readonly],
  .form-control[readonly]:focus {
    background-color: $white;
    border-color: $white !important;
    box-shadow: none !important;
  }

  .message-body {
    // https://codepen.io/anon/pen/ejbGRE
    // TODO: loop through config objects
    .ql-picker {
      &.ql-font {
        .ql-picker-label {
          &[data-value="Georgia, Times New Roman, serif"]::before {
            content: 'Serif';
          }

          &[data-value="Monaco, Courier New, monospace"]::before {
            content: 'Monospace';
          }
        }

        .ql-picker-item {
          &[data-value="Georgia, Times New Roman, serif"]::before {
            content: 'Serif';
            font-family: Georgia, 'Times New Roman', serif;
          }

          &[data-value="Monaco, Courier New, monospace"]::before {
            content: 'Monospace';
            font-family: Monaco, 'Courier New', monospace;
          }
        }
      }

      &.ql-size {
        .ql-picker-label {
          &[data-value="9px"]::before {
            content: 'Small';
          }

          &[data-value="18px"]::before {
            content: 'Large';
          }

          &[data-value="30px"]::before {
            content: 'Huge';
          }
        }

        .ql-picker-item {
          &[data-value="9px"]::before {
            content: 'Small';
            font-size: 9px;
          }

          &[data-value="18px"]::before {
            content: 'Large';
            font-size: 18px;
          }

          &[data-value="30px"]::before {
            content: 'Huge';
            font-size: 30px;
          }
        }
      }
    }

    .ql-personalisation {
      width: 130px;

      .ql-picker-options {
        max-height: 180px;
        overflow: auto;
      }
    }
    .ql-personalisation-edoc {
      width: 130px;

      .ql-picker-options {
        max-height: 180px;
        overflow: auto;
      }
    }


    &.is-invalid {
      .ql-toolbar,
      .ql-container {
        border-left-color: $danger;
        border-right-color: $danger;
      }

      .ql-toolbar {
        border-top-color: $danger;
      }

      .ql-container {
        border-bottom-color: $danger;
      }

      & ~ .invalid-feedback {
        display: block;
      }
    }
  }


  .email-delay-send {
    align-items: center;
    display: flex;
    margin-bottom: 2em;
    margin-top: 2em;

    .form-check-inline {
      top: 0.1em;
    }

    .email-delay-send-range {
      display: flex;
      flex: 1 1 auto;

      p {
        flex: 0 1 auto;
        margin-right: 0.5em;
      }
    }

    .hour-range {
      flex: 1 1 auto;
      margin-left: 1em;
      margin-right: 1em;
    }
  }
}
.ql-personalisation-edoc > span > svg {
  top: 10px !important;
  right: -15px !important;
}

.email-reply-to {
  height: 25px;
  background-color: $action-colour;
  margin-bottom: 10px;
  p {
    padding: 2px 5px 2px 5px;
    color: #FFF;
    display: inline-block;
  }

  a {
    color: #ffffff !important;
    font-weight: bold;
    padding: 2px 5px 2px 0;
    cursor: pointer;
  }
}
.email-toolbar {
  select {
    padding-bottom: 6px;
    padding-left: 6px;
    padding-top: 6px;
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;

    button {
      flex-grow: 0;

      &.is-displayed {
        font-weight: $font-weight-bold;
        text-decoration: underline;
      }
    }

    button:last-child {
      padding-right: 0;
    }
  }
}

.rejection-confirmation-radios {
  .form-check-inline {
    display: inline-flex ;

    .form-check-input[type=radio] {
      margin-top: 0;
    }
  }
}

#textarea-emoji {
  top: 40px;
}

.candidate-comms {
  .tab-list {
    align-items: center;
    background-color: $slider-background;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
  }

  .tab-item {
    display: block;
    flex: 6;
    margin: 0;
    padding: 0;

    &.tab-item-active {
      background-color: $white;
      border: 0;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;

      a {
        span {
          border-bottom-color: $teal;
        }
      }
    }

    a {
      justify-content: center;
      padding: 3px 0 5px;
      position: relative;
      user-select: none;

      &::after {
        background-color: $white;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        width: 1px;
      }

      svg {
        display: none;
      }

      span {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }

      &:hover {
        span {
          border-bottom-color: $teal;
        }
      }
    }

    &:first-child,
    &.tab-item-active,
    &.tab-item-active + .tab-item {
      a {
        &::after {
          display: none;
        }
      }
    }
  }

  .tab-wrapper {
    padding: 0 1rem 1rem;
  }

  .interview-form {
    .event-input-radio {
      margin-left: calc(16.66667% + 0.5em);
    }
  }
  
  .form-check-input[type=radio] {
    margin-top: -0.5px;
  }

  input[type=checkbox] + span {
    top: 1px;
  }

}

.chat-error-banner {
  background: $danger;
  color: $white;
  width: 100%;
  font-weight: bold;
  padding: 10px 0 10px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.chat-bubbles {
  height: calc(100vh - 510px);
  min-height: 200px;
  overflow: auto;
}

.chat-bubble-container {
  margin-bottom: 20px;
  min-width: 100%;
}

.chat-user-image {
  width: 50px;
  display: inline-block;
}

.chat-bubble {
  max-width: 80%;
  min-width: 200px;
  border-radius: 0 10px 10px 10px;
  display: inline-block;
  background-color: #f5f7fb;
}


.chat-bubble > p {
  padding: 5px 20px 5px 20px;
}

p.chat-bubble-header {
  font-size: 11px;
  font-weight: bold;
  width: 100%;
  padding: 5px 5px 5px 10px;
}

p.chat-bubble-title {
  font-size: 12px;
  width: 100%;
  padding: 0 0 0 20px;
}

.chat-is-sent {
  background-color: $blue;
  color: #fff;
}

.chat-selection-icon {
  display: inline-block;
  margin: 0 0 10px 10px;
  cursor: pointer;
}

.chat-bubble-status-container {
  float: right;
  margin-right:5px;
  width: 100%;
}

.chat-email-compose-container {
  padding: 20px;
  background-color: #FFF;
  border-radius: 10px;
  border: 1px solid #eeeeee;
}

.chat-reply-btn {
  background-color: transparent !important;
  border: none !important;
}

.chat-reply-btn:active {
  color: #FFF !important;
}

.chat-attachments-container {
  width: 100%;
  height: 35px;
  border: 1px solid #436aa1;
  border-radius: 3px;
  background-color: rgba(216, 225, 255, 0.65);
  p {
    margin-right: 5px;
    font-weight: bold;
  }
}

.chat-attachments-container > p, .chat-attachments-icon-container {
  display: inline-block;
}

.chat-attachments-icon-container {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  margin-top: 2px;
}

.chat-attachments-button {
  background: none;
  width: 100%;
  height: 35px;
  text-align: left;
  border: none;
  margin-bottom: 10px;
  outline: none;
}

.chat-attachments-button:focus, .chat-attachments-button:active {
  outline: none;
}

.inline-block {
  display: inline-block;
}

.chat-attachments-warning {
  color: #ffffff;
}
